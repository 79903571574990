<template>
  <!-- class="relative h-48 w-[310px] transform cursor-pointer overflow-hidden rounded-lg border border-transparent p-[18px] transition-all duration-200 hover:-translate-y-1 hover:shadow-lg 2xl:w-[380px]" -->
  <div
    class="relative h-[200px] transform overflow-hidden rounded-lg border border-transparent transition-all duration-200 hover:-translate-y-1 hover:shadow-lg"
    :class="[
      { ' !cursor-not-allowed': issue?.hasIssue || userRole === 'viewer' },
      { 'cursor-pointer': !issue || issue?.hasIssue === false },
      {
        '!bg-orange-200 ': showWarning
      },
      {
        'bg-linkedin-100 hover:border-linkedin-600 dark:bg-linkedin-900':
          linkedin || recruiterLite || crunchbase || email
      },
      {
        'bg-twitter-100 hover:border-twitter-600 dark:bg-twitter-900': twitter
      },
      {
        'bg-facebook-100 hover:border-facebook-600 dark:bg-facebook-900':
          facebook
      },
      {
        'bg-instagram-100 hover:border-instagram-400 dark:bg-instagram-300':
          instagram
      },
      {
        'dark:bg-reddit-900 bg-reddit-100 hover:border-reddit-600':
          reddit || productHunt
      },
      {
        'bg-youtube-100 hover:border-youtube-600 dark:bg-youtube-900': youtube
      },
      {
        'bg-dropcontact-100 hover:border-dropcontact-600 dark:bg-dropcontact-900':
          dropcontact
      },
      {
        'bg-yellowpages-100 hover:border-yellowpages-600 dark:bg-yellowpages-400':
          yellowpages
      },
      {
        'bg-websites-100 hover:border-websites-600 ': websites
      },

      {
        'bg-gray-100 hover:border-gray-500 dark:bg-gray-400':
          google || openai || github || zoominfo
      },
      {
        'bg-purple-100 hover:border-purple-500 ': slack
      }
    ]"
  >
    <div class="h-full">
      <div
        class="flex items-center justify-between w-full pr-1 pt-2"
        :class="[
          { ' cursor-not-allowed': issue?.hasIssue },
          { 'cursor-pointer': !issue }
        ]"
      >
        <div>
          <div
            v-if="issue?.hasIssue"
            @mouseover="showWarning = true"
            @mouseleave="showWarning = false"
            class="text-orange-500 pl-[18px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm.01 13.33l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -7a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                stroke-width="0"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <Popper hover>
          <div
            @click="onPinIconClick()"
            :class="[
              { 'text-gray-300 hover:text-gray-800': isPinned === false },
              { 'text-gray-800': isPinned === true }
            ]"
          >
            <SvgIcon name="pin" />
          </div>
          <template #content>
            <div class="w-max max-w-lg rounded px-2 text-sm">
              {{ isPinned ? 'Unpin' : 'Pin on Top' }}
            </div>
          </template>
        </Popper>
      </div>
      <Transition>
        <div v-if="showWarning" class="bg-orange-200 px-[18px] h-full">
          <div class="flex flex-col">
            <div
              class="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-full text-white"
            >
              <img
                :src="platformIcon"
                alt="logo"
                class="h-8 w-8 shrink-0 rounded-full object-cover"
              />
            </div>
            <div>
              <p class="min-w-0 text-base font-semibold text-gray-900 2xl:mt-2">
                This Automation Is Under Maintainance
              </p>
              <p
                class="overflow-hidden text-sm font-normal text-gray-500 line-clamp-3"
              >
                {{ issue.description }}
              </p>
            </div>
          </div>
        </div>

        <div v-else @click="cardClick" class="px-[18px] h-full">
          <div class="flex flex-col space-y-1 2xl:space-y-4">
            <div
              class="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-full text-white"
            >
              <img
                :src="platformIcon"
                alt="logo"
                class="h-8 w-8 shrink-0 rounded-full object-cover"
              />
            </div>
            <div>
              <p
                class="min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-base font-semibold text-gray-900 dark:text-gray-50 2xl:mt-2"
                :class="[
                  {
                    'dark:text-gray-900': instagram
                  }
                ]"
              >
                {{ name }}
              </p>
              <p
                class="overflow-hidden text-sm font-normal text-gray-500 line-clamp-3 dark:text-gray-400"
                :class="[
                  {
                    'dark:text-gray-800': instagram
                  },
                  {
                    'text-[#5d6b98]': websites
                  }
                ]"
              >
                {{ label }}
              </p>
            </div>
          </div>

          <div class="absolute bottom-3 right-5">
            <Button
              :class="[{ ' cursor-not-allowed': userRole === 'viewer' }]"
              size="small"
              color="secondary"
              text="Try it!"
            />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import SvgIcon from '../SvgIcon.vue'
import Popper from 'vue3-popper'
import { mapState } from 'vuex'

import { pinAutomation, unpinAutomation } from '@/apis/user'

export default {
  name: 'AutomationCards',
  emits: ['onTryClick', 'automationPinning', 'error'],
  props: {
    label: { type: String },
    name: { type: String },
    platform: { type: String },
    platformIcon: { type: String },
    index: { type: Number },
    platformOperationId: { type: String },
    isPinned: { type: Boolean },
    issue: {},
    skipBulkInput: {}
  },
  computed: {
    ...mapState('settings', ['userRole']),
    linkedin() {
      if (
        this.platform === 'linkedin' ||
        this.platform === 'sales navigator' ||
        this.platform === 'texau agents'
      ) {
        return true
      }
    },
    twitter() {
      if (this.platform === 'twitter' || this.platform === 'x (twitter)') {
        return true
      }
    },
    zoominfo() {
      if (this.platform === 'zoominfo') {
        return true
      }
    },
    facebook() {
      if (this.platform === 'facebook') {
        return true
      }
    },
    instagram() {
      if (this.platform === 'instagram') {
        return true
      }
    },
    reddit() {
      if (this.platform === 'reddit') {
        return true
      }
    },
    productHunt() {
      if (this.platform === 'product hunt') {
        return true
      }
    },
    crunchbase() {
      if (this.platform === 'crunchbase') {
        return true
      }
    },
    youtube() {
      if (this.platform === 'youtube') {
        return true
      }
    },
    dropcontact() {
      if (this.platform === 'dropcontact') {
        return true
      }
    },
    yellowpages() {
      if (this.platform === 'yellow pages') {
        return true
      }
    },
    google() {
      if (this.platform === 'google') {
        return true
      }
    },
    websites() {
      if (this.platform === 'websites') {
        return true
      }
    },
    openai() {
      if (this.platform === 'open ai') {
        return true
      }
    },
    github() {
      if (this.platform === 'github') {
        return true
      }
    },
    email() {
      if (this.platform === 'email') {
        return true
      }
    },
    slack() {
      if (this.platform === 'slack') {
        return true
      }
    },
    recruiterLite() {
      if (this.platform === 'recruiter lite') {
        return true
      }
    }
  },

  data() {
    return {
      showWarning: false
    }
  },
  methods: {
    async onPinIconClick() {
      try {
        if (this.isPinned) {
          const response = await unpinAutomation(this.platformOperationId)
          if (response['success']) {
            this.$emit('automationPinning', {
              id: this.platformOperationId,
              status: 'unpin'
            })
          }
        } else {
          const response = await pinAutomation(this.platformOperationId)
          if (response['success']) {
            this.$emit('automationPinning', {
              id: this.platformOperationId,
              status: 'pin'
            })
          }
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    cardClick() {
      if ((this.issue && this.issue?.hasIssue) || this.userRole === 'viewer')
        return
      this.$emit('onTryClick', {
        name: this.name,
        platformOperationId: this.platformOperationId,
        skipBulkInput: this.skipBulkInput
      })
    }
  },
  components: { Button, SvgIcon, Popper }
}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
