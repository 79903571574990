<template>
  <!-- <MfaConfirmSection
    v-if="this.showMfaConfirmationModal"
    :secret-key="secretKey"
    :on-close="onModalClose"
  /> -->

  <div class="mx-auto mt-8 grid max-w-xl grid-cols-1 gap-y-8">
    <div class="flex items-center">
      <span v-if="mfaOn" class="text-sm font-semibold text-green-500">
        2 Factor Authentication Enabled
      </span>
      <span
        v-else
        class="text-sm font-semibold text-gray-900 dark:text-gray-50"
      >
        Activate 2 Factor Authentication
      </span>
      <button
        type="button"
        class="elative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-60 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
        role="switch"
        aria-checked="false"
        :aria-checked="on.toString()"
        :disabled="mfaUpdating"
        @click="updatingMfaStatus()"
        :class="{
          '!bg-blue-600 dark:bg-blue-500': on,
          'bg-gray-400 dark:bg-gray-500': !on
        }"
      >
        <span
          aria-hidden="true"
          class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          :class="{ 'translate-x-5': on, 'translate-x-0': !on }"
        ></span>
      </button>
      <Spinner v-if="mfaUpdating" class="ml-6" />
    </div>

    <div>
      <label
        for=""
        class="block text-sm font-medium text-gray-900 dark:text-gray-50"
      >
        Registered Phone Number
      </label>
      <div class="relative mt-1.5">
        <Input :key="componentKey" :modelValue="phoneNumber" :disabled="true" />

        <div class="absolute inset-y-0 right-0 flex items-center pr-3">
          <SvgIcon name="lock" />
        </div>
      </div>
      <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
        ** For changing phone number, please contact
        <a
          href="#"
          title=""
          class="font-semibold text-blue-600 hover:underline dark:text-blue-400"
          >support</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
import SecurityHeader from '@/components/settings/securityHeader.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import { mapGetters } from 'vuex'
import { disableMfa, startMfaAssociation } from '@/apis/user'
import MfaConfirmSection from '@/components/settings/mfaConfirmSection.vue'
import UserProfile from '@/common/userWrapper'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'twoFactorAuthenticationSection',
  components: {
    SecurityHeader,
    SvgIcon,
    Input,
    MfaConfirmSection,
    Spinner
  },
  data() {
    return {
      on: true,
      phoneNumber: '',
      componentKey: 0,
      mfaUpdating: false,
      secretKey: '',
      showMfaConfirmationModal: false,
      mfaOn: false
    }
  },

  mounted() {
    this.setMfaStatus()
  },

  computed: {
    ...mapGetters('user', ['getUserData']),
    ...mapGetters('settings', ['getMfaStatus'])
  },

  watch: {
    getUserData: {
      handler(newValue, oldValue) {
        const { phone } = new Proxy(newValue, {})
        this.phoneNumber = phone ? phone : 'Loading...'
        this.componentKey += 1
      },
      deep: true,
      immediate: true
    },

    getMfaStatus: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.mfaUpdating = false
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    setMfaStatus() {
      const user = window.$cookies.get('user')
      this.on = user.isMfaEnabled
      this.mfaOn = user.isMfaEnabled
    },

    async updatingMfaStatus() {
      if (this.on) {
        // disabling mfa
        this.$emit('showRemoveMfaModal')
        // this.showRemoveMfaModal = true
      } else {
        this.on = true
        this.mfaUpdating = true
        const response = await startMfaAssociation()
        if (response['success']) {
          this.$emit('openMfaConfirmationModal', response.data.secretKey)
          this.secretKey = response.data.secretKey
          this.showMfaConfirmationModal = true
        }
      }
    },
    async confirmRemoveMfa() {
      this.on = false
      this.mfaUpdating = true
      const response = await disableMfa()
      this.mfaUpdating = false
      if (response['success']) {
        await this.updateMfaStatusInCookies()
        this.setMfaStatus()
      }
    },

    updateMfaStatusInCookies() {
      let user_ = UserProfile.getUser()
      user_['isMfaEnabled'] = false
      UserProfile.setUser(user_)
    },

    onModalClose() {
      this.setMfaStatus()
      this.showMfaConfirmationModal = false
      this.mfaUpdating = false
    }
  }
}
</script>

<style scoped></style>
