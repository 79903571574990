import {
  addWorkflowNode,
  createWorkflow,
  getWorkflow,
  updateWorkflow,
  updateWorkflowNode,
  getWorkflowAllNodes
} from '@/apis/workflows'
import { WORKFLOW_CREATED_FROM, constants } from '@/common/constants'
import { store } from '@/store/store'

const getWorkflowIdNew = async source => {
  try {
    const response = await createWorkflow(
      source,
      store._state.data.automationStore.automationStoreData.name,
      store._state.data.automationStore.automationStoreData.label,
      WORKFLOW_CREATED_FROM.STORE,
      store._state.data.automationStore.automationStoreData.platform
    )

    if (!response['success']) {
      throw response.message
    }

    let workflowId = response.data._id

    //if automation schedule data present update workflow
    if (store._state.data.automationStore.automationScheduleData) {
      await updateWorkflow(
        workflowId,
        store._state.data.automationStore.automationScheduleData
      )
    }

    if (store._state.data.automationStore.delayAutomationNaming) {
      await updateWorkflow(workflowId, {
        name: store._state.data.automationStore.automationNameText
      })
      store.commit('automationStore/SET_DELAY_AUTOMATION_NAMING', false)
    }
    store.commit('automationStore/ADD_DATA', {
      workflowId: response.data._id
    })

    return {
      workflowId: workflowId,
      operationId:
        store._state.data.automationStore.automationStoreData
          .platformOperationId,
      source: source
    }
  } catch (error) {
    throw error
  }
}

const getNodeIdNew = async workflowId => {
  try {
    let data = null
    for (const key in store._state.data.automationStore.automationInputs) {
      if (key === 'connectedAccountId') continue
      data = {
        ...data,
        [key]: store._state.data.automationStore.automationInputs[key]
      }
    }

    if (data === null) {
      data = {}
    }
    //create node date for the automation
    let nodeData = {
      platformOperationId:
        store._state.data.automationStore.automationStoreData
          .platformOperationId,
      platformId:
        store._state.data.automationStore.automationStoreData.platformId,
      label: store._state.data.automationStore.automationStoreData.label,
      nextNode: '',
      previousNode: '',
      connectedAccountId: store._state.data.automationStore.connectedAccountId,
      isConfigured: true,
      inputs: data,
      ...store._state.data.automationStore.automationOutputMode
    }

    //if desktop data is present add it to node
    if (store._state.data.automationStore.automationDesktopData !== null) {
      nodeData.desktopId =
        store._state.data.automationStore.automationDesktopData._id
    }

    const response = await addWorkflowNode(workflowId, nodeData)

    if (!response['success']) {
      throw response.message
    }

    store.commit('automationStore/ADD_DATA', {
      nodeId: response.data._id
    })

    await workflowUpdateNew()
    return response.data._id
  } catch (error) {
    throw error
  }
}

const getNodeIdNewForSheetsOrCsv = async (workflowId, source) => {
  try {
    let firstNodeInputData, nodeData, secondNodeData, firstNodeId
    const keys = store._state.data.workflow.dynamicOutputs.keys()
    firstNodeId = keys.next().value
    if (source === 'sheet') {
      firstNodeInputData = {
        googleSpreadsheetId:
          store._state.data.automationStore.automationInputs
            .googleSpreadsheetId,
        googleSheetId:
          store._state.data.automationStore.automationInputs.googleSheetId,

        noOfRowsToProcess:
          store._state.data.automationStore.automationInputs.noOfRowsToProcess,
        noOfRowsToSkip:
          store._state.data.automationStore.automationInputs.noOfRowsToSkip,
        isLoop:
          store._state.data.automationStore.automationInputs.loopCheck ?? false
      }

      nodeData = {
        id: firstNodeId,
        dynamicOutputs: [
          ...store._state.data.automationStore.automationStoreData
            .dynamicOutputs
        ],
        platformOperationId: constants.googlePlatformOperationId,
        platformId: constants.googlePlatformId,
        label: 'Google Sheet Input',
        nextNode: '',
        previousNode: '',
        outputMode: 'append',
        isDedupeResult: true,
        isConfigured: true,
        connectedAccountId:
          store._state.data.automationStore.connectedAccountIdGoogleSheets,
        inputs: firstNodeInputData
      }

      // Create first node
      const addNodeResponse = await addWorkflowNode(workflowId, nodeData)

      if (!addNodeResponse['success']) {
        throw addNodeResponse.message
      }

      const updateNodeResponse = await updateWorkflowNode(
        workflowId,
        firstNodeId,
        {
          dynamicOutputs: [
            ...store._state.data.automationStore.automationStoreData
              .dynamicOutputs
          ]
        }
      )

      if (!updateNodeResponse['success']) {
        throw updateNodeResponse.message
      }

      // Update workflow with first node as starting node
      const updateWorkflowResponse = await updateWorkflow(workflowId, {
        startNode: firstNodeId
      })

      if (!updateWorkflowResponse['success']) {
        throw updateWorkflowResponse.message
      }

      let secondNodeInputData = {
        ...store._state.data.automationStore.automationInputs
      }

      delete secondNodeInputData['googleSheetId']
      delete secondNodeInputData['googleSpreadsheetId']
      secondNodeData = {
        platformOperationId:
          store._state.data.automationStore.automationStoreData
            .platformOperationId, //automation platformOperationId
        platformId:
          store._state.data.automationStore.automationStoreData.platformId, //automation platform Id
        label: store._state.data.automationStore.automationStoreData.label,
        nextNode: '',
        previousNode: firstNodeId,
        isConfigured: true,
        connectedAccountId:
          store._state.data.automationStore.connectedAccountId, //automation connected account
        inputs: secondNodeInputData,
        ...store._state.data.automationStore.automationOutputMode
      }
      //add iterationDelayConfig
      if (store._state.data.automationStore.iterationDelay != null) {
        secondNodeData.iterationDelayConfig =
          store._state.data.automationStore.iterationDelay
      }
    } else {
      firstNodeInputData = {
        csvHeaders: store._state.data.automationStore.csvHeaders,
        csvUrl: store._state.data.automationStore.automationInputs.csvUrl,
        fileName: store._state.data.automationStore.automationInputs.fileName,
        noOfRowsToProcess:
          store._state.data.automationStore.automationInputs.noOfRowsToProcess,
        noOfRowsToSkip:
          store._state.data.automationStore.automationInputs.noOfRowsToSkip
      }
      nodeData = {
        id: firstNodeId,
        platformOperationId: constants.csvPlatformOperationId,
        platformId: constants.csvPlatformId,
        label: 'Csv Input',
        nextNode: '',
        previousNode: '',
        outputMode: 'append',
        isDedupeResult: true,
        isConfigured: true,
        inputs: { ...firstNodeInputData }
      }

      //Create first node
      const addNodeResponse = await addWorkflowNode(workflowId, nodeData)

      if (!addNodeResponse['success']) {
        throw addNodeResponse.message
      }

      const updateWorkflowResponse = await updateWorkflow(workflowId, {
        startNode: firstNodeId
      })

      if (!updateWorkflowResponse['success']) {
        throw updateWorkflowResponse.message
      }

      let secondNodeInputData = {
        ...store._state.data.automationStore.automationInputs
      }

      secondNodeData = {
        platformOperationId:
          store._state.data.automationStore.automationStoreData
            .platformOperationId, //automation platformOperationId
        platformId:
          store._state.data.automationStore.automationStoreData.platformId, //automation platform Id
        label: store._state.data.automationStore.automationStoreData.label,
        nextNode: '',
        previousNode: firstNodeId,
        isConfigured: true,
        connectedAccountId:
          store._state.data.automationStore.connectedAccountId, //automation connected account
        inputs: secondNodeInputData,
        ...store._state.data.automationStore.automationOutputMode
      }
      //add iterationDelayConfig
      if (store._state.data.automationStore.iterationDelay != null) {
        secondNodeData.iterationDelayConfig =
          store._state.data.automationStore.iterationDelay
      }
    }

    //if desktop data is present add it to node
    if (store._state.data.automationStore.automationDesktopData !== null) {
      secondNodeData.desktopId =
        store._state.data.automationStore.automationDesktopData._id
    }

    //Create a second node
    const secondNode = await addWorkflowNode(workflowId, secondNodeData)

    if (!secondNode['success']) {
      throw secondNode.message
    }

    let secondNodeId = secondNode.data._id
    store.commit('automationStore/ADD_SECOND_NODE_ID', secondNodeId)

    // first node update with next node of second node
    await updateWorkflowNode(workflowId, firstNodeId, {
      nextNode: secondNodeId
    })

    return {
      nodeId: firstNodeId,
      secondNodeId: secondNodeId
    }
  } catch (error) {
    throw error
  }
}

const workflowUpdateNew = async () => {
  await updateWorkflow(
    store._state.data.automationStore.automationStoreData.workflowId,
    {
      startNode: store._state.data.automationStore.automationStoreData.nodeId
    }
  )
}

const updateWorkflowWithNewData = async workflowId => {
  try {
    const response = await getWorkflow(workflowId)

    if (!response['success']) {
      throw response.message
    }

    const startNode = response.data.startNode
    let data = null
    for (const key in store._state.data.automationStore.automationInputs) {
      if (key === 'connectedAccountId') continue
      data = {
        ...data,
        [key]: store._state.data.automationStore.automationInputs[key]
      }
    }

    if (data === null) {
      data = {}
    }

    let obj = {
      inputs: data,
      connectedAccountId: store._state.data.automationStore.connectedAccountId,
      isConfigured: true
    }

    //if desktop data is present add it to node
    if (store._state.data.automationStore.automationDesktopData !== null) {
      obj.desktopId =
        store._state.data.automationStore.automationDesktopData._id
    } else {
      obj.desktopId = null
    }

    const updateNodeResponse = await updateWorkflowNode(
      workflowId,
      startNode,
      obj
    )

    if (!updateNodeResponse['success']) {
      throw updateNodeResponse.message
    }
  } catch (error) {
    throw error
  }
}
const updateWorkflowNewDataForSheetsOrCsv = async (workflowId, source) => {
  try {
    let firstNodeInputData,
      nodeData,
      secondNodeInputData,
      firstNodeId,
      secondNodeId
    const response = await getWorkflowAllNodes(workflowId)

    if (!response['success']) {
      throw response.message
    }

    firstNodeId = response.data[0]._id
    secondNodeId = response.data[0].nextNode
    store.commit('automationStore/ADD_SECOND_NODE_ID', secondNodeId)
    if (source === 'sheet') {
      firstNodeInputData = {
        [store._state.data.automationStore.automationStoreData.variablesData[0]
          .name]:
          store._state.data.automationStore.automationInputs
            .googleSpreadsheetId,
        [store._state.data.automationStore.automationStoreData.variablesData[1]
          .name]:
          store._state.data.automationStore.automationInputs.googleSheetId,
        isLoop:
          store._state.data.automationStore.automationInputs.loopCheck ?? false
      }

      //Update first node with input data of spreadsheetId & sheetId
      const firstNodeInputUpdate = await updateWorkflowNode(
        workflowId,
        firstNodeId,
        {
          dynamicOutputs: [
            ...store._state.data.automationStore.automationStoreData
              .dynamicOutputs
          ],
          inputs: firstNodeInputData,
          connectedAccountId:
            store._state.data.automationStore.connectedAccountIdGoogleSheets,
          isConfigured: true
        }
      )

      if (!firstNodeInputUpdate['success']) {
        throw firstNodeInputUpdate.message
      }

      secondNodeInputData = {
        ...store._state.data.automationStore.automationInputs
      }
    } else {
      firstNodeInputData = {
        csvHeaders: store._state.data.automationStore.csvHeaders,
        csvUrl: store._state.data.automationStore.automationInputs.csvUrl,
        fileName: store._state.data.automationStore.automationInputs.fileName
      }

      const firstNodeInputUpdate = await updateWorkflowNode(
        workflowId,
        firstNodeId,
        {
          inputs: { ...firstNodeInputData },
          isConfigured: true
        }
      )

      if (!firstNodeInputUpdate['success']) {
        throw firstNodeInputUpdate.message
      }

      secondNodeInputData = {
        ...store._state.data.automationStore.automationInputs
      }
    }

    let obj = {
      inputs: secondNodeInputData,
      connectedAccountId: store._state.data.automationStore.connectedAccountId,
      isConfigured: true
    }

    //add iterationDelayConfig
    if (store._state.data.automationStore.iterationDelay != null) {
      obj.iterationDelayConfig =
        store._state.data.automationStore.iterationDelay
    }

    //if desktop data is present add it to node
    if (store._state.data.automationStore.automationDesktopData !== null) {
      obj.desktopId =
        store._state.data.automationStore.automationDesktopData._id
    } else {
      obj.desktopId = null
    }

    // second node input update
    const secondNodeInputUpdate = await updateWorkflowNode(
      workflowId,
      secondNodeId,
      obj
    )

    if (!secondNodeInputUpdate['success']) {
      throw secondNodeInputUpdate.message
    }
  } catch (error) {
    throw error
  }
}

/**
 * This function returns a message depending on whether plan is allocated to a user or not.
 */
const showVerifyMessage = userData => {
  const baseMessage = 'Before running automations, we need to verify your email'
  const additionalMessage =
    ' and phone number to secure your account and ensure you receive important updates. This also activates your trial.'

  const message = userData.hasOwnProperty('planAllocated')
    ? `${baseMessage}${additionalMessage}`
    : baseMessage

  return message
}

export {
  getWorkflowIdNew,
  getNodeIdNew,
  getNodeIdNewForSheetsOrCsv,
  workflowUpdateNew,
  updateWorkflowWithNewData,
  updateWorkflowNewDataForSheetsOrCsv,
  showVerifyMessage
}
