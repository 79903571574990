export const saveUserData = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_USER_DATA', payload)
}
export const updateUserData = ({ commit, dispatch }, { payload }) => {
  commit('UPDATE_USER_DATA', payload)
}

export const updateUserDataWorkflow = ({ commit }, { firstWorkflowRun }) => {
  commit('UPDATE_USER_DATA_WORKFLOW', { firstWorkflowRun })
}

export const saveExecutionTimeLeft = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_EXECUTION_TIME_LEFT', payload)
}

export const saveNotificationNumber = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_NOTIFICATION_NUMBER', payload)
}

export const saveWorkspaceData = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_WORKSPACE_DATA', payload)
}

export const saveEmailVerifiersLeft = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_EMAIL_VERIFIERS_LEFT', payload)
}

export const saveEmailEnrichmentsLeft = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_EMAIL_ENRICHMENTS_LEFT', payload)
}

export const saveSocialAccountsLeft = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_SOCIAL_ACCOUNTS_LEFT', payload)
}

export const saveSocialAccountsALLOWED = (
  { commit, dispatch },
  { payload }
) => {
  commit('SAVE_SOCIAL_ACCOUNTS_ALLOWED', payload)
}

export const saveProxyAccess = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_PROXY_ACCESS', payload)
}

export const changeExpandedDefault = ({ commit, dispatch }, { payload }) => {
  commit('CHANGE_EXPANDED_DEFAULT', payload)
}

export const saveDesktopAccess = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_DESKTOP_ACCESS', payload)
}
export const saveAiAccess = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_AI_ACCESS', payload)
}
export const saveIntegrationAccess = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_INTEGRATION_ACCESS', payload)
}
export const saveApiAccess = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_API_ACCESS', payload)
}
export const SAVE_ADDITIONAL_DATA = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_ADDITIONAL_DATA', payload)
}
