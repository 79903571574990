<template>
  <div class="flex w-[220px] 2xl:max-w-xs">
    <Categories
      :options="categoryOptions"
      :selected="selectedCategory"
      @categoryUpdate="categoryUpdate"
    />
  </div>
  <div
    class="relative flex h-full min-w-0 flex-1 flex-col border-l border-gray-200 dark:border-gray-700"
  >
    <div class="flex h-full items-center justify-center" v-if="pageLoading">
      <Spinner class="flex h-full items-center justify-center" size="large" />
    </div>

    <div v-if="!pageLoading" class="shrink-0">
      <div class="px-4 pt-5 sm:px-6 sm:pt-6">
        <div
          class="mt-6 rounded-lg border border-gray-200 dark:border-gray-700"
        >
          <!-- TODO: will need the classes for later -->
          <!-- <div class="px-4 py-5 sm:p-6"> -->
          <!-- <div class="grid grid-cols-2 gap-5"> -->
          <div class="px-4 py-5 sm:p-6">
            <div class="grid grid-cols-2 gap-5">
              <div>
                <div>
                  <Select
                    :leftImageUrl="platformIcon"
                    text="Select platform"
                    :options="platformNames"
                    v-model="selectedPlatform"
                    @change="setUrl"
                    :key="platformNames"
                    :includeSearch="true"
                    label="Platform"
                    :hideClear="true"
                  />
                </div>
              </div>

              <div>
                <label
                  for=""
                  class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  Search Automations
                </label>
                <div class="mt-1.5">
                  <input
                    v-model="searchString"
                    v-debounce:300ms="searchAutomation"
                    type="text"
                    id="search-input"
                    class="block w-full rounded-lg border border-gray-300 px-3 py-2.5 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                    placeholder="Automation name 🔍"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-5 border-gray-200 dark:border-gray-700" />
      </div>
    </div>

    <div
      ref="el"
      v-if="!pageLoading"
      class="flex-1 overflow-y-scroll border-l-0"
    >
      <h2
        v-if="!cardLoading"
        class="pt-4 text-center text-lg font-bold 3xl:col-span-4"
      >
        {{ selectedCategory }}
      </h2>
      <div v-else class="flex pt-4 justify-center">
        <Skeleton height="28px" width="100px" borderRadius="8px"></Skeleton>
      </div>
      <div class="mt-8" v-if="cardArray.length === 0 && !cardLoading">
        <p class="text-center text-blue-600">
          No Automations Available For This Category
        </p>
      </div>
      <div
        v-if="cardArray.length > 0"
        class="grid grid-cols-3 gap-6 px-4 py-5 sm:p-6 3xl:grid-cols-4"
      >
        <AutomationCards
          v-if="
            $route.query.category !== 'Custom Workflows' &&
            $route.query.category !== 'Factory Workflows'
          "
          v-for="(data, index) in cardArray"
          :index="index"
          :key="data._id"
          :name="data.name"
          :label="data.description"
          :platform="selectedPlatform.toLowerCase()"
          :platformIcon="platformIcon"
          :platformOperationId="data.platformOperationId"
          :isPinned="data.isPinned"
          :issue="data?.issue"
          :skipBulkInput="data?.skipBulkInput"
          @onTryClick="automationCardClick"
          @automationPinning="automationPinning"
          @error="e => $emit('error', e)"
          @trigger-menu="selectedRow = $event"
        />

        <PublicWorkflowCards
          v-else-if="$route.query.category === 'Factory Workflows'"
          v-for="(data, index) in cardArray"
          :workflowData="data"
          :key="data.name"
          :platformIcon="platformIcon"
          @error="e => $emit('error', e)"
          @trigger-menu="selectedRow = $event"
        />

        <WorkflowCards
          v-else-if="$route.query.category === 'Custom Workflows'"
          v-for="(data, index) in cardArray"
          :data="data"
          @error="e => $emit('error', e)"
          @trigger-menu="selectedRow = $event"
        />
      </div>
      <div
        class="flex items-center justify-center gap-1 absolute bottom-4 right-4 rounded bg-white bg-opacity-80 p-2 text-sm text-gray-400"
        v-if="cardLoading && cardArray.length > 0"
      >
        Loading automations <Spinner size="small" />
      </div>
      <div
        class="grid grid-cols-3 gap-6 px-4 py-5 sm:p-6 3xl:grid-cols-4"
        v-if="cardLoading && cardArray.length === 0"
      >
        <div
          v-for="ele of [1, 2, 3, 4, 5, 6, 7, 8]"
          :key="ele"
          class="relative h-[200px] transform cursor-pointer overflow-hidden rounded-lg border border-transparent transition-all duration-200 hover:-translate-y-1 hover:shadow-lg"
        >
          <Skeleton height="100%" width="100%" borderRadius="8px"></Skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Spinner from '@/components/Spinner.vue'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Input from '../Input.vue'
import Select from '../Select.vue'
import AutomationCards from './AutomationCards.vue'
import Categories from './Categories.vue'
import { fetchCustomWorkflows, getPublicWorkflows } from '@/apis/workflows'
// import Menu from '@/components/dataStore/menu.vue'

//API CALL
import {
  getAllPlatforms,
  getPlatformOperations
} from '@/apis/automation-store/Page1'
import { EVENTS_TO_BE_TRACKED } from '@/common/constants'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import { useInfiniteScroll } from '@/common/useInfiniteScroll'
import WorkflowCards from '@/components/automationStore/WorkflowCards.vue'
import PublicWorkflowCards from '@/components/automationStore/PublicWorkflowCards.vue'

const store = useStore()
const emit = defineEmits(['error'])
const router = useRouter()
const route = useRoute()
const el = ref(null)
const optionsPlatform = ref(null)
const platformIcon = ref(null)
const platformNames = ref(null)
const selectedPlatform = ref(null)
const selectedCategory = ref(null)
const searchString = ref(null)
const platformId = ref(null)
const pageLoading = ref(true)
const cardLoading = ref(false)
const cardArray = ref([])
const total = ref(null)
const selectedRow = ref(null)
const categoryOptions = ref([
  {
    name: 'All',
    icon: 'list',
    svg: '<svg class="h-5 w-5" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path><path d="M9 7l6 0"></path><path d="M9 11l6 0"></path><path d="M9 15l4 0"></path></svg>'
  },
  {
    name: 'Search & Collect',
    icon: 'search',
    svg: '<svg class="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" stroke="currentColor"><path d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
  },
  {
    name: 'Scan',
    icon: 'Scan',
    svg: '<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 6H18V8H16V6ZM16 20C17.1 20 18 19.1 18 18H16V20ZM16 14H18V16H16V14ZM16 10H18V12H16V10ZM6 4V2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H6V18H2V4H6ZM16 2V4H18C18 2.9 17.1 2 16 2ZM8 0H10V22H8V0ZM12 2H14V4H12V2ZM12 18H14V20H12V18Z" fill="currentColor"/></svg>'
  },
  {
    name: 'Communicate',
    icon: 'Communicate',
    svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 2V9H3.17L2.58 9.59L2 10.17V2H13ZM14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10V1C15 0.45 14.55 0 14 0ZM19 4H17V13H4V15C4 15.55 4.45 16 5 16H16L20 20V5C20 4.45 19.55 4 19 4Z" fill="currentColor"/></svg>'
  },
  {
    name: 'React',
    icon: 'React',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_2_2841)"><path opacity="0.3" d="M13.34 4.66L9 9V19H18L21 12V10H12.23L13.34 4.66Z" fill="currentColor"/><path d="M21 8H14.69L15.64 3.43L15.67 3.11C15.67 2.7 15.5 2.32 15.23 2.05L14.17 1L7.59 7.59C7.22 7.95 7 8.45 7 9V19C7 20.1 7.9 21 9 21H18C18.83 21 19.54 20.5 19.84 19.78L22.86 12.73C22.95 12.5 23 12.26 23 12V10C23 8.9 22.1 8 21 8ZM21 12L18 19H9V9L13.34 4.66L12.23 10H21V12ZM1 9H5V21H1V9Z" fill="currentColor"/></g><defs><clipPath id="clip0_2_2841"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>'
  },
  {
    name: 'Network',
    icon: 'Network',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none"><path d="M21 6.5C19.34 6.5 18 7.84 18 9.5C18 9.57 18 9.64 18.01 9.71L15.98 10.39C15.34 9.18 14.16 8.3 12.76 8.07V5.91C14.04 5.57 15 4.4 15 3C15 1.34 13.66 0 12 0C10.34 0 9 1.34 9 3C9 4.4 9.96 5.57 11.25 5.91V8.07C9.85 8.3 8.67 9.18 8.03 10.39L5.99 9.71C6 9.64 6 9.57 6 9.5C6 7.84 4.66 6.5 3 6.5C1.34 6.5 0 7.84 0 9.5C0 11.16 1.34 12.5 3 12.5C4.06 12.5 4.98 11.95 5.52 11.13L7.55 11.81C7.35 13.1 7.72 14.47 8.64 15.5L7.23 17.27C6.85 17.09 6.44 17 6 17C4.34 17 3 18.34 3 20C3 21.66 4.34 23 6 23C7.66 23 9 21.66 9 20C9 19.32 8.78 18.7 8.4 18.2L9.81 16.43C11.17 17.19 12.83 17.18 14.18 16.43L15.59 18.2C15.22 18.7 15 19.32 15 20C15 21.66 16.34 23 18 23C19.66 23 21 21.66 21 20C21 18.34 19.66 17 18 17C17.56 17 17.15 17.09 16.77 17.26L15.36 15.49C16.29 14.45 16.65 13.09 16.45 11.8L18.48 11.12C19.01 11.94 19.94 12.49 21 12.49C22.66 12.49 24 11.15 24 9.49C24 7.83 22.66 6.5 21 6.5ZM3 10.5C2.45 10.5 2 10.05 2 9.5C2 8.95 2.45 8.5 3 8.5C3.55 8.5 4 8.95 4 9.5C4 10.05 3.55 10.5 3 10.5ZM6 21C5.45 21 5 20.55 5 20C5 19.45 5.45 19 6 19C6.55 19 7 19.45 7 20C7 20.55 6.55 21 6 21ZM11 3C11 2.45 11.45 2 12 2C12.55 2 13 2.45 13 3C13 3.55 12.55 4 12 4C11.45 4 11 3.55 11 3ZM12 15C10.62 15 9.5 13.88 9.5 12.5C9.5 11.12 10.62 10 12 10C13.38 10 14.5 11.12 14.5 12.5C14.5 13.88 13.38 15 12 15ZM18 19C18.55 19 19 19.45 19 20C19 20.55 18.55 21 18 21C17.45 21 17 20.55 17 20C17 19.45 17.45 19 18 19ZM21 10.5C20.45 10.5 20 10.05 20 9.5C20 8.95 20.45 8.5 21 8.5C21.55 8.5 22 8.95 22 9.5C22 10.05 21.55 10.5 21 10.5Z" fill="currentColor"/></svg>'
  },
  {
    name: 'Enrich',
    icon: 'mail-verification',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_2_2849)"><path opacity="0.3" d="M20 8V12.08C19.67 12.03 19.34 12 19 12C15.47 12 12.57 14.61 12.08 18H4V8L12 13L20 8ZM20 6H4L12 11L20 6Z" fill="currentcolor"/><path d="M12.08 18H4V8L12 13L20 8V12.08C20.71 12.18 21.38 12.39 22 12.68V6C22 4.9 21.1 4 20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H12.08C12.03 19.67 12 19.34 12 19C12 18.66 12.03 18.33 12.08 18ZM20 6L12 11L4 6H20ZM17.34 22L13.8 18.46L15.21 17.05L17.33 19.17L21.57 14.93L23 16.34L17.34 22Z" fill="currentcolor"/></g><defs><clipPath id="clip0_2_2849"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>'
  },
  {
    name: 'AI',
    icon: 'stars',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19 9L20.25 6.25L23 5L20.25 3.75L19 1L17.75 3.75L15 5L17.75 6.25L19 9Z" fill="CURRENTCOLOR"/><path d="M19 15L17.75 17.75L15 19L17.75 20.25L19 23L20.25 20.25L23 19L20.25 17.75L19 15Z" fill="CURRENTCOLOR"/><path d="M11.5 9.5L9 4L6.5 9.5L1 12L6.5 14.5L9 20L11.5 14.5L17 12L11.5 9.5ZM9.99 12.99L9 15.17L8.01 12.99L5.83 12L8.01 11.01L9 8.83L9.99 11.01L12.17 12L9.99 12.99Z" fill="CURRENTCOLOR"/></svg>'
  },
  {
    name: 'Factory Workflows',
    icon: 'flask',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_2_2868)"><path d="M20.8 18.4L15 10.67V6.5L16.35 4.81C16.61 4.48 16.38 4 15.96 4H8.03999C7.61999 4 7.38999 4.48 7.64999 4.81L8.99999 6.5V10.67L3.19999 18.4C2.70999 19.06 3.17999 20 3.99999 20H20C20.82 20 21.29 19.06 20.8 18.4ZM5.99999 18L11 11.33V6H13V11.33L18 18H5.99999Z" fill="currentcolor"/></g><defs><clipPath id="clip0_2_2868"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>'
  },
  {
    name: 'Custom Workflows',
    icon: 'bulb',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_2_2879)"><path opacity="0.3" d="M14 18H10V19H14V18Z" fill="CURRENTCOLOR"/><path opacity="0.3" d="M14 16H10V17H14V16Z" fill="CURRENTCOLOR"/><path d="M12 3C11.54 3 11.07 3.04 10.6 3.14C7.84001 3.67 5.64001 5.9 5.12001 8.66C4.64001 11.27 5.60001 13.67 7.34001 15.22C7.77001 15.6 8.00001 16.13 8.00001 16.69V19C8.00001 20.1 8.90001 21 10 21H10.28C10.63 21.6 11.26 22 12 22C12.74 22 13.38 21.6 13.72 21H14C15.1 21 16 20.1 16 19V16.69C16 16.14 16.22 15.6 16.64 15.23C18.09 13.95 19 12.08 19 10C19 6.13 15.87 3 12 3ZM14 19H10V18H14V19ZM14 17H10V16H14V17ZM15.31 13.74C15.22 13.82 15.15 13.92 15.07 14H8.92001C8.84001 13.91 8.77001 13.81 8.68001 13.73C7.36001 12.55 6.77001 10.79 7.09001 9.03C7.45001 7.09 9.05001 5.48 10.98 5.1C11.32 5.03 11.66 5 12 5C14.76 5 17 7.24 17 10C17 11.43 16.39 12.79 15.31 13.74Z" fill="CURRENTCOLOR"/><path d="M12.5 11H11.5V14H12.5V11Z" fill="CURRENTCOLOR"/><path d="M10.3795 8.87371L9.67242 9.58081L11.7937 11.7021L12.5008 10.995L10.3795 8.87371Z" fill="CURRENTCOLOR"/><path d="M11.501 11.0052L12.2081 11.7123L14.3294 9.59098L13.6223 8.88388L11.501 11.0052Z" fill="CURRENTCOLOR"/></g><defs><clipPath id="clip0_2_2879"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>'
  }
])

const controller = ref(new AbortController())

watch(selectedCategory, n => {
  router.push({
    query: {
      ...route.query,
      category: n
    }
  })
})

watch(searchString, n => {
  router.push({
    query: {
      ...route.query,
      q: n || undefined
    }
  })
})

const fetchAutomationsBasedOnFilters = () => {
  if (selectedCategory.value === 'All' && !!searchString.value) {
    // If selectedCategory is 'ALL' and searchString has a value
    fetchAutomations()
  } else if (selectedCategory.value === 'All') {
    // If selectedCategory is 'ALL' but searchString is empty
    getAutomationType(true)
  } else if (selectedCategory.value === 'Factory Workflows') {
    fetchPublicWorkflows()
  } else if (selectedCategory.value === 'Custom Workflows') {
    fetchWorkflows()
  } else {
    // If selectedCategory is not 'ALL'
    fetchAutomations()
  }
}

onMounted(async () => {
  await getOptionPlatforms()
  selectedPlatform.value = route.query.platform || null
  selectedCategory.value = route.query.category || 'All'
  searchString.value = route.query.q
  setPlatformId()
  fetchAutomationsBasedOnFilters()
})

//get all the platforms
const getOptionPlatforms = async () => {
  try {
    let platforms
    if (!sessionStorage.allPlatforms) {
      const response = await getAllPlatforms(0, 50, '')
      sessionStorage.setItem('allPlatforms', JSON.stringify(response.data))
      platforms = response.data
    } else {
      platforms = JSON.parse(sessionStorage.allPlatforms)
    }

    optionsPlatform.value = platforms
    platformNames.value = platforms
      .filter(item => item.type === 'automation' || item.name === 'openai')
      .map(item => {
        return { label: item.label, value: item.label, img: item.logoUrl }
      })
  } catch (error) {
    emit('error', error)
  }
  pageLoading.value = false
}

//when platform change update the query and set new data
const setUrl = () => {
  let category = route.query.category
  router.push({
    query: {
      platform: selectedPlatform.value,
      category
    }
  })
  cardArray.value = []

  setPlatformId()
  fetchAutomationsBasedOnFilters()
}

// set the id of the platform globally
const setPlatformId = () => {
  const id = optionsPlatform.value.find(
    item => item.label === selectedPlatform.value
  )

  platformId.value = id?._id
  platformIcon.value = id?.logoUrl
}

//send true in params to get and set the values from the session storage
const getAutomationType = async init => {
  if (!selectedPlatform.value) return

  if (selectedPlatform.value === 'Open AI') {
    await fetchAutomations()
    return
  }

  if (init && sessionStorage.automationStore) {
    let obj = JSON.parse(sessionStorage.automationStore)
    if (selectedPlatform.value in obj) {
      cardArray.value = obj[selectedPlatform.value].automations
    } else {
      await fetchAutomations(true)
    }
  } else {
    await fetchAutomations(true)
  }
}

const cancelOldRequest = () => {
  controller.value.abort('request cancelled')
  controller.value = new AbortController()
}

//send true in params to store the response in session storage
//fetch new automations and store the value in card array
const fetchAutomations = async init => {
  cardLoading.value = true
  try {
    cancelOldRequest()
    const response = await getPlatformOperations(
      platformId.value,
      cardArray.value.length,
      20,
      searchString.value,
      selectedCategory.value === 'All' ? null : selectedCategory.value,
      controller.value.signal
    )
    // return immediately if the request was canceled
    if (response === 'canceled') {
      return
    }
    if (response['success']) {
      total.value = response.total
      cardArray.value.push(...response.data)
      init && storeinSession(response)
    } else {
      throw response.message
    }
  } catch (error) {
    emit('error', error)
  }
  cardLoading.value = false
}

const fetchPublicWorkflows = async () => {
  cardLoading.value = true
  try {
    cancelOldRequest()
    //fetch workflows from builder
    const response = await getPublicWorkflows(
      cardArray.value.length,
      20,
      searchString.value
    )
    // return immediately if the request was canceled
    if (response === 'canceled') {
      return
    }
    if (response['success']) {
      total.value = response.total
      cardArray.value.push(...response.data)
    } else {
      throw response.message
    }
  } catch (error) {
    emit('error', error)
  }
  cardLoading.value = false
}

const fetchWorkflows = async () => {
  cardLoading.value = true
  try {
    cancelOldRequest()
    //fetch workflows from builder
    const response = await fetchCustomWorkflows(
      cardArray.value.length,
      20,
      searchString.value,
      platformId.value
    )
    // return immediately if the request was canceled
    if (response === 'canceled') {
      return
    }
    if (response['success']) {
      total.value = response.total
      cardArray.value.push(...response.data)
    } else {
      throw response.message
    }
  } catch (error) {
    emit('error', error)
  }
  cardLoading.value = false
}

// stores the data in session storage
//will not store if a category is selected or searchstring exist
const storeinSession = response => {
  if (selectedCategory.value !== 'All') {
    return
  }

  if (!!searchString.value) {
    return
  }

  if (!sessionStorage.automationStore) {
    sessionStorage.setItem('automationStore', JSON.stringify({}))
  }
  const obj = JSON.parse(sessionStorage.automationStore)
  if (!(selectedPlatform.value in obj)) {
    obj[selectedPlatform.value] = { automations: [] }
  }
  if (selectedPlatform.value in obj) {
    obj[selectedPlatform.value].automations.push(...response.data)
    sessionStorage.setItem('automationStore', JSON.stringify(obj))
  }
}

//fetch new automation data when apporaches end of div
useInfiniteScroll(el, {
  onLoadMore: async () => {
    if (cardArray.value.length === total.value) {
      return
    } else if (route.query.category === 'Factory Workflows') {
      await fetchPublicWorkflows()
    } else if (route.query.category === 'Custom Workflows') {
      await fetchWorkflows()
    } else {
      await getAutomationType()
    }
  }
})

//proceed to new event
const automationCardClick = async data => {
  amplitudeTrackEvent(
    EVENTS_TO_BE_TRACKED.AUTOMATION_CLICKED,
    localStorage.getItem('email'),
    { automationName: data.name }
  )
  await store.dispatch('automationStore/addPlatformData', {
    payload: {
      metaData: {
        logo: platformIcon.value,
        name: data.name
      }
    }
  })

  if (data.skipBulkInput) {
    router.push({
      path: '/automation-store/workflow',
      query: {
        operationId: data.platformOperationId,
        source: 'input'
      }
    })
    return
  }

  await router.push({
    path: '/automation-store/workflow/create',
    query: {
      operationId: data.platformOperationId,
      platformId: platformId.value
    }
  })
}

/* 
 fetch new data when category is updated 
 wouldn't fetch new data instead pick up data from session storage when category is "All" and 
 searchstring does not exist
*/
const categoryUpdate = async data => {
  selectedCategory.value = data
  cardArray.value = []
  if (data === 'All' && !!searchString.value) {
    // If data is 'ALL' and searchString has a value
    await fetchAutomations()
  } else if (data === 'All') {
    // If data is 'ALL' but searchString is empty
    await getAutomationType(true)
  } else if (data === 'Factory Workflows') {
    await fetchPublicWorkflows()
  } else if (data === 'Custom Workflows') {
    await fetchWorkflows()
  } else {
    // If data is not 'ALL'
    await fetchAutomations()
  }
}

/* 
  debounce function will fetch new data according to searchsting provided
  if searchstring is empty and category is all get data from session storage
*/
const searchAutomation = async text => {
  cardArray.value = []
  if (text === '' && selectedCategory.value === 'All') {
    await getAutomationType(true)
  } else if (route.query.category === 'Factory Workflows') {
    await fetchPublicWorkflows()
  } else if (route.query.category === 'Custom Workflows') {
    await fetchWorkflows()
  } else {
    await fetchAutomations()
  }
}

const automationPinning = data => {
  //find index of automation with same platformOperationId id
  let index = cardArray.value.findIndex(obj => {
    return obj.platformOperationId === data.id
  })

  //check if automation with given id exists
  if (index !== -1) {
    //remove the automation from its current position
    let automation = cardArray.value.splice(index, 1)[0]

    //check if status is pinning or unpinning
    if (data.status === 'pin') {
      //update the status of isPinned
      automation.isPinned = true

      //place it at the top of array to show the automation first
      cardArray.value.unshift(automation)

      //same process with the session storage automation to update its status as well
      const obj = JSON.parse(sessionStorage.automationStore)
      let sessionIndex = obj[selectedPlatform.value].automations.findIndex(
        obj => obj.platformOperationId === data.id
      )
      //check if that automation exist in session storage
      if (sessionIndex === -1) {
        obj[selectedPlatform.value].automations.unshift(automation)
        sessionIndex = 0
      }
      let sessionAutomation = obj[selectedPlatform.value].automations.splice(
        sessionIndex,
        1
      )[0]
      sessionAutomation.isPinned = true
      obj[selectedPlatform.value].automations.unshift(sessionAutomation)
      sessionStorage.setItem('automationStore', JSON.stringify(obj))
    } else {
      automation.isPinned = false
      cardArray.value.push(automation)
      const obj = JSON.parse(sessionStorage.automationStore)

      let sessionIndex = obj[selectedPlatform.value].automations.findIndex(
        obj => obj.platformOperationId === data.id
      )
      let sessionAutomation = obj[selectedPlatform.value].automations.splice(
        sessionIndex,
        1
      )[0]
      sessionAutomation.isPinned = false
      obj[selectedPlatform.value].automations.push(sessionAutomation)
      sessionStorage.setItem('automationStore', JSON.stringify(obj))
    }
  }
}
</script>

<style>
.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 20px;
}
</style>
