const env = import.meta.env
export const constants = {
  BACKEND_API_BASE_URL: env.VITE_BACKEND_API_BASE_URL,
  BACKEND_API_VERSION: env.VITE_BACKEND_API_VERSION,
  WEBHOOK_API_BASE_URL: env.VITE_WEBHOOK_API_BASE_URL,
  AMPLITUDE_KEY: env.VITE_AMPLITUDE_KEY,
  csvPlatformId: env.VITE_CSV_PLATFORMID,
  csvPlatformOperationId: env.VITE_CSV_PLATFORM_OPERATION_ID,
  googlePlatformId: env.VITE_GOOGLE_SHEETS_PLATFORMID,
  googlePlatformOperationId: env.VITE_GOOGLE_SHEETS_PLATFORM_OPERATION_ID,
  googleOutputPlatformOperationId:
    env.VITE_GOOGLE_SHEETS_OUTPUT_PLATFORM_OPERATION_ID,
  highlight: env.VITE_HIGHLIGHT_PROJECT_ID,
  growSurfCampaignId: env.VITE_GROWSURF_CAMPAIGN_ID,
  growSurfAPI: env.VITE_GROWSURF_API,
  intercomAppid: env.VITE_INTERCOM_APP_ID,
  OPENAI_PLATFORM_ID: env.VITE_OPENAI_PLATFORM_ID,
  LINKEDIN_PLATFORM_ID: env.VITE_LINKEDIN_PLATFORM_ID,
  LINKEDIN_PROFILE_SCRAPER_ID: env.VITE_LINKEDIN_PROFILE_SCRAPER_ID,
  LINKEDIN_PEOPLE_SEARCH_ID: env.VITE_LINKEDIN_PEOPLE_SEARCH_ID,
  TWITTER_THREAD_ID: env.VITE_TWITTER_THREAD_ID,
  Intercom_Secret_key: env.VITE_INTERCOM_SECRET_KEY,
  chargebeeSite: env.VITE_CHARGEBEE_SITE,
  microsoftClarityId: env.VITE_MICROSOFT_CLARITY_APP_ID,
  gTagId: env.VITE_GTAG_APP_ID,
  environment: env.VITE_ENVIRONMENT,
  sentryDsn: env.VITE_SENTRY_DSN
}

export const phoneCountryCodes = [
  {
    value: '+7 840',
    label: 'Abkhazia'
  },
  {
    value: '+93',
    label: 'Afghanistan'
  },
  {
    value: '+355',
    label: 'Albania'
  },
  {
    value: '+213',
    label: 'Algeria'
  },
  {
    value: '+1 684',
    label: 'American Samoa'
  },
  {
    value: '+376',
    label: 'Andorra'
  },
  {
    value: '+244',
    label: 'Angola'
  },
  {
    value: '+1 264',
    label: 'Anguilla'
  },
  {
    value: '+1 268',
    label: 'Antigua and Barbuda'
  },
  {
    value: '+54',
    label: 'Argentina'
  },
  {
    value: '+374',
    label: 'Armenia'
  },
  {
    value: '+297',
    label: 'Aruba'
  },
  {
    value: '+247',
    label: 'Ascension'
  },
  {
    value: '+61',
    label: 'Australia'
  },
  {
    value: '+672',
    label: 'Australian External Territories'
  },
  {
    value: '+43',
    label: 'Austria'
  },
  {
    value: '+994',
    label: 'Azerbaijan'
  },
  {
    value: '+1 242',
    label: 'Bahamas'
  },
  {
    value: '+973',
    label: 'Bahrain'
  },
  {
    value: '+880',
    label: 'Bangladesh'
  },
  {
    value: '+1 246',
    label: 'Barbados'
  },
  {
    value: '+1 268',
    label: 'Barbuda'
  },
  {
    value: '+375',
    label: 'Belarus'
  },
  {
    value: '+32',
    label: 'Belgium'
  },
  {
    value: '+501',
    label: 'Belize'
  },
  {
    value: '+229',
    label: 'Benin'
  },
  {
    value: '+1 441',
    label: 'Bermuda'
  },
  {
    value: '+975',
    label: 'Bhutan'
  },
  {
    value: '+591',
    label: 'Bolivia'
  },
  {
    value: '+387',
    label: 'Bosnia and Herzegovina'
  },
  {
    value: '+267',
    label: 'Botswana'
  },
  {
    value: '+55',
    label: 'Brazil'
  },
  {
    value: '+246',
    label: 'British Indian Ocean Territory'
  },
  {
    value: '+1 284',
    label: 'British Virgin Islands'
  },
  {
    value: '+673',
    label: 'Brunei'
  },
  {
    value: '+359',
    label: 'Bulgaria'
  },
  {
    value: '+226',
    label: 'Burkina Faso'
  },
  {
    value: '+257',
    label: 'Burundi'
  },
  {
    value: '+855',
    label: 'Cambodia'
  },
  {
    value: '+237',
    label: 'Cameroon'
  },
  {
    value: '+1',
    label: 'Canada'
  },
  {
    value: '+238',
    label: 'Cape Verde'
  },
  {
    value: '+ 345',
    label: 'Cayman Islands'
  },
  {
    value: '+236',
    label: 'Central African Republic'
  },
  {
    value: '+235',
    label: 'Chad'
  },
  {
    value: '+56',
    label: 'Chile'
  },
  {
    value: '+86',
    label: 'China'
  },
  {
    value: '+61',
    label: 'Christmas Island'
  },
  {
    value: '+61',
    label: 'Cocos-Keeling Islands'
  },
  {
    value: '+57',
    label: 'Colombia'
  },
  {
    value: '+269',
    label: 'Comoros'
  },
  {
    value: '+242',
    label: 'Congo'
  },
  {
    value: '+243',
    label: 'Congo, Dem. Rep. of (Zaire)'
  },
  {
    value: '+682',
    label: 'Cook Islands'
  },
  {
    value: '+506',
    label: 'Costa Rica'
  },
  {
    value: '+385',
    label: 'Croatia'
  },
  {
    value: '+53',
    label: 'Cuba'
  },
  {
    value: '+599',
    label: 'Curacao'
  },
  {
    value: '+357',
    label: 'Cyprus'
  },
  {
    value: '+420',
    label: 'Czech Republic'
  },
  {
    value: '+45',
    label: 'Denmark'
  },
  {
    value: '+246',
    label: 'Diego Garcia'
  },
  {
    value: '+253',
    label: 'Djibouti'
  },
  {
    value: '+1 767',
    label: 'Dominica'
  },
  {
    value: '+1 809',
    label: 'Dominican Republic'
  },
  {
    value: '+670',
    label: 'East Timor'
  },
  {
    value: '+56',
    label: 'Easter Island'
  },
  {
    value: '+593',
    label: 'Ecuador'
  },
  {
    value: '+20',
    label: 'Egypt'
  },
  {
    value: '+503',
    label: 'El Salvador'
  },
  {
    value: '+240',
    label: 'Equatorial Guinea'
  },
  {
    value: '+291',
    label: 'Eritrea'
  },
  {
    value: '+372',
    label: 'Estonia'
  },
  {
    value: '+251',
    label: 'Ethiopia'
  },
  {
    value: '+500',
    label: 'Falkland Islands'
  },
  {
    value: '+298',
    label: 'Faroe Islands'
  },
  {
    value: '+679',
    label: 'Fiji'
  },
  {
    value: '+358',
    label: 'Finland'
  },
  {
    value: '+33',
    label: 'France'
  },
  {
    value: '+596',
    label: 'French Antilles'
  },
  {
    value: '+594',
    label: 'French Guiana'
  },
  {
    value: '+689',
    label: 'French Polynesia'
  },
  {
    value: '+241',
    label: 'Gabon'
  },
  {
    value: '+220',
    label: 'Gambia'
  },
  {
    value: '+995',
    label: 'Georgia'
  },
  {
    value: '+49',
    label: 'Germany'
  },
  {
    value: '+233',
    label: 'Ghana'
  },
  {
    value: '+350',
    label: 'Gibraltar'
  },
  {
    value: '+30',
    label: 'Greece'
  },
  {
    value: '+299',
    label: 'Greenland'
  },
  {
    value: '+1 473',
    label: 'Grenada'
  },
  {
    value: '+590',
    label: 'Guadeloupe'
  },
  {
    value: '+1 671',
    label: 'Guam'
  },
  {
    value: '+502',
    label: 'Guatemala'
  },
  {
    value: '+224',
    label: 'Guinea'
  },
  {
    value: '+245',
    label: 'Guinea-Bissau'
  },
  {
    value: '+595',
    label: 'Guyana'
  },
  {
    value: '+509',
    label: 'Haiti'
  },
  {
    value: '+504',
    label: 'Honduras'
  },
  {
    value: '+852',
    label: 'Hong Kong SAR China'
  },
  {
    value: '+36',
    label: 'Hungary'
  },
  {
    value: '+354',
    label: 'Iceland'
  },
  {
    value: '+91',
    label: 'India'
  },
  {
    value: '+62',
    label: 'Indonesia'
  },
  {
    value: '+964',
    label: 'Iraq'
  },
  {
    value: '+353',
    label: 'Ireland'
  },
  {
    value: '+972',
    label: 'Israel'
  },
  {
    value: '+39',
    label: 'Italy'
  },
  {
    value: '+225',
    label: 'Ivory Coast'
  },
  {
    value: '+1 876',
    label: 'Jamaica'
  },
  {
    value: '+81',
    label: 'Japan'
  },
  {
    value: '+962',
    label: 'Jordan'
  },
  {
    value: '+7 7',
    label: 'Kazakhstan'
  },
  {
    value: '+254',
    label: 'Kenya'
  },
  {
    value: '+686',
    label: 'Kiribati'
  },
  {
    value: '+965',
    label: 'Kuwait'
  },
  {
    value: '+996',
    label: 'Kyrgyzstan'
  },
  {
    value: '+856',
    label: 'Laos'
  },
  {
    value: '+371',
    label: 'Latvia'
  },
  {
    value: '+961',
    label: 'Lebanon'
  },
  {
    value: '+266',
    label: 'Lesotho'
  },
  {
    value: '+231',
    label: 'Liberia'
  },
  {
    value: '+218',
    label: 'Libya'
  },
  {
    value: '+423',
    label: 'Liechtenstein'
  },
  {
    value: '+370',
    label: 'Lithuania'
  },
  {
    value: '+352',
    label: 'Luxembourg'
  },
  {
    value: '+853',
    label: 'Macau SAR China'
  },
  {
    value: '+389',
    label: 'Macedonia'
  },
  {
    value: '+261',
    label: 'Madagascar'
  },
  {
    value: '+265',
    label: 'Malawi'
  },
  {
    value: '+60',
    label: 'Malaysia'
  },
  {
    value: '+960',
    label: 'Maldives'
  },
  {
    value: '+223',
    label: 'Mali'
  },
  {
    value: '+356',
    label: 'Malta'
  },
  {
    value: '+692',
    label: 'Marshall Islands'
  },
  {
    value: '+596',
    label: 'Martinique'
  },
  {
    value: '+222',
    label: 'Mauritania'
  },
  {
    value: '+230',
    label: 'Mauritius'
  },
  {
    value: '+262',
    label: 'Mayotte'
  },
  {
    value: '+52',
    label: 'Mexico'
  },
  {
    value: '+691',
    label: 'Micronesia'
  },
  {
    value: '+1 808',
    label: 'Midway Island'
  },
  {
    value: '+373',
    label: 'Moldova'
  },
  {
    value: '+377',
    label: 'Monaco'
  },
  {
    value: '+976',
    label: 'Mongolia'
  },
  {
    value: '+382',
    label: 'Montenegro'
  },
  {
    value: '+1664',
    label: 'Montserrat'
  },
  {
    value: '+212',
    label: 'Morocco'
  },
  {
    value: '+95',
    label: 'Myanmar'
  },
  {
    value: '+264',
    label: 'Namibia'
  },
  {
    value: '+674',
    label: 'Nauru'
  },
  {
    value: '+977',
    label: 'Nepal'
  },
  {
    value: '+31',
    label: 'Netherlands'
  },
  {
    value: '+599',
    label: 'Netherlands Antilles'
  },
  {
    value: '+1 869',
    label: 'Nevis'
  },
  {
    value: '+687',
    label: 'New Caledonia'
  },
  {
    value: '+64',
    label: 'New Zealand'
  },
  {
    value: '+505',
    label: 'Nicaragua'
  },
  {
    value: '+227',
    label: 'Niger'
  },
  {
    value: '+234',
    label: 'Nigeria'
  },
  {
    value: '+683',
    label: 'Niue'
  },
  {
    value: '+672',
    label: 'Norfolk Island'
  },
  {
    value: '+850',
    label: 'North Korea'
  },
  {
    value: '+1 670',
    label: 'Northern Mariana Islands'
  },
  {
    value: '+47',
    label: 'Norway'
  },
  {
    value: '+968',
    label: 'Oman'
  },
  {
    value: '+92',
    label: 'Pakistan'
  },
  {
    value: '+680',
    label: 'Palau'
  },
  {
    value: '+970',
    label: 'Palestinian Territory'
  },
  {
    value: '+507',
    label: 'Panama'
  },
  {
    value: '+675',
    label: 'Papua New Guinea'
  },
  {
    value: '+595',
    label: 'Paraguay'
  },
  {
    value: '+51',
    label: 'Peru'
  },
  {
    value: '+63',
    label: 'Philippines'
  },
  {
    value: '+48',
    label: 'Poland'
  },
  {
    value: '+351',
    label: 'Portugal'
  },
  {
    value: '+1 787',
    label: 'Puerto Rico'
  },
  {
    value: '+974',
    label: 'Qatar'
  },
  {
    value: '+262',
    label: 'Reunion'
  },
  {
    value: '+40',
    label: 'Romania'
  },
  {
    value: '+7',
    label: 'Russia'
  },
  {
    value: '+250',
    label: 'Rwanda'
  },
  {
    value: '+685',
    label: 'Samoa'
  },
  {
    value: '+378',
    label: 'San Marino'
  },
  {
    value: '+966',
    label: 'Saudi Arabia'
  },
  {
    value: '+221',
    label: 'Senegal'
  },
  {
    value: '+381',
    label: 'Serbia'
  },
  {
    value: '+248',
    label: 'Seychelles'
  },
  {
    value: '+232',
    label: 'Sierra Leone'
  },
  {
    value: '+65',
    label: 'Singapore'
  },
  {
    value: '+421',
    label: 'Slovakia'
  },
  {
    value: '+386',
    label: 'Slovenia'
  },
  {
    value: '+677',
    label: 'Solomon Islands'
  },
  {
    value: '+27',
    label: 'South Africa'
  },
  {
    value: '+500',
    label: 'South Georgia and the South Sandwich Islands'
  },
  {
    value: '+82',
    label: 'South Korea'
  },
  {
    value: '+34',
    label: 'Spain'
  },
  {
    value: '+94',
    label: 'Sri Lanka'
  },
  {
    value: '+249',
    label: 'Sudan'
  },
  {
    value: '+597',
    label: 'Suriname'
  },
  {
    value: '+268',
    label: 'Swaziland'
  },
  {
    value: '+46',
    label: 'Sweden'
  },
  {
    value: '+41',
    label: 'Switzerland'
  },
  {
    value: '+963',
    label: 'Syria'
  },
  {
    value: '+886',
    label: 'Taiwan'
  },
  {
    value: '+992',
    label: 'Tajikistan'
  },
  {
    value: '+255',
    label: 'Tanzania'
  },
  {
    value: '+66',
    label: 'Thailand'
  },
  {
    value: '+670',
    label: 'Timor Leste'
  },
  {
    value: '+228',
    label: 'Togo'
  },
  {
    value: '+690',
    label: 'Tokelau'
  },
  {
    value: '+676',
    label: 'Tonga'
  },
  {
    value: '+1 868',
    label: 'Trinidad and Tobago'
  },
  {
    value: '+216',
    label: 'Tunisia'
  },
  {
    value: '+90',
    label: 'Turkey'
  },
  {
    value: '+993',
    label: 'Turkmenistan'
  },
  {
    value: '+1 649',
    label: 'Turks and Caicos Islands'
  },
  {
    value: '+688',
    label: 'Tuvalu'
  },
  {
    value: '+1 340',
    label: 'U.S. Virgin Islands'
  },
  {
    value: '+256',
    label: 'Uganda'
  },
  {
    value: '+380',
    label: 'Ukraine'
  },
  {
    value: '+971',
    label: 'United Arab Emirates'
  },
  {
    value: '+44',
    label: 'United Kingdom'
  },
  {
    value: '+1',
    label: 'United States'
  },
  {
    value: '+598',
    label: 'Uruguay'
  },
  {
    value: '+998',
    label: 'Uzbekistan'
  },
  {
    value: '+678',
    label: 'Vanuatu'
  },
  {
    value: '+58',
    label: 'Venezuela'
  },
  {
    value: '+84',
    label: 'Vietnam'
  },
  {
    value: '+1 808',
    label: 'Wake Island'
  },
  {
    value: '+681',
    label: 'Wallis and Futuna'
  },
  {
    value: '+967',
    label: 'Yemen'
  },
  {
    value: '+260',
    label: 'Zambia'
  },
  {
    value: '+255',
    label: 'Zanzibar'
  },
  {
    value: '+263',
    label: 'Zimbabwe'
  }
]

export const timeZones = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Nelson',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'CET',
  'CST6CDT',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Cuba',
  'EET',
  'EST',
  'EST5EDT',
  'Egypt',
  'Eire',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/GMT+1',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT-0',
  'Etc/GMT-1',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
  'Etc/GMT-14',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/GMT0',
  'Etc/Greenwich',
  'Etc/UCT',
  'Etc/UTC',
  'Etc/Universal',
  'Etc/Zulu',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'Greenwich',
  'HST',
  'Hongkong',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'MST',
  'MST7MDT',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'NZ',
  'NZ-CHAT',
  'Navajo',
  'PRC',
  'PST8PDT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Pacific-New',
  'US/Samoa',
  'UTC',
  'Universal',
  'W-SU',
  'WET',
  'Zulu'
]

export const locations = [
  'Amsterdam, Netherlands',
  'Anchorage, United States',
  'Athens, Greece',
  'Auckland, New Zealand',
  'Bangkok, Thailand',
  'Barcelona, Spain',
  'Beijing, China',
  'Belgrade, Serbia',
  'Berlin, Germany',
  'Bratislava, Slovakia',
  'Brussels, Belgium',
  'Bucharest, Romania',
  'Budapest, Hungary',
  'Buenos Aires, Argentina',
  'Cairo, Egypt',
  'Cape Town, South Africa',
  'Caracas, Venezuela',
  'Chicago, United States',
  'Copenhagen, Denmark',
  'Dubai, United Arab Emirates',
  'Dublin, Ireland',
  'Frankfurt, Germany',
  'Geneva, Switzerland',
  'Helsinki, Finland',
  'Ho Chi Minh City, Vietnam',
  'Hong Kong, China',
  'Istanbul, Turkey',
  'Jakarta, Indonesia',
  'Johannesburg, South Africa',
  'Kiev, Ukraine',
  'Kuala Lumpur, Malaysia',
  'Lima, Peru',
  'Lisbon, Portugal',
  'Los Angeles, United States',
  'Madrid, Spain',
  'Melbourne, Australia',
  'Mexico City, Mexico',
  'Miami, United States',
  'Milan, Italy',
  'Montreal, Canada',
  'Moscow, Russia',
  'Mumbai, India',
  'Munich, Germany',
  'New York, United States',
  'Oslo, Norway',
  'Paris, France',
  'Philadelphia, United States',
  'Phnom Penh, Cambodia',
  'Prague, Czech Republic',
  'Rio de Janeiro, Brazil',
  'Rome, Italy',
  'San Francisco, United States',
  'Santiago, Chile',
  'Sao Paulo, Brazil',
  'Seattle, United States',
  'Seoul, South Korea',
  'Shanghai, China',
  'Singapore',
  'Stockholm, Sweden',
  'Sydney, Australia',
  'Taipei, Taiwan',
  'Tel Aviv, Israel',
  'Tokyo, Japan',
  'Toronto, Canada',
  'Vancouver, Canada',
  'Vienna, Austria',
  'Warsaw, Poland',
  'Washington, United States',
  'Zurich, Switzerland'
]

export const notificationTypes = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  UPDATE: 'update',
  PAUSED: 'paused'
}

export const GRID_SIZE = 72

export const NODE_TYPE_NAMES = {
  AUTOMATION_NODE: 'automationNode',
  UTILITY_NODE: 'utilityNode'
}

export const NODE_SIZES = {
  [NODE_TYPE_NAMES.AUTOMATION_NODE]: 80,
  [NODE_TYPE_NAMES.UTILITY_NODE]: 40
}

export const NODE_SIZE_DIFFERENCE = {
  [NODE_TYPE_NAMES.UTILITY_NODE + NODE_TYPE_NAMES.AUTOMATION_NODE]:
    (NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] -
      NODE_SIZES[NODE_TYPE_NAMES.UTILITY_NODE]) /
    2,
  [NODE_TYPE_NAMES.AUTOMATION_NODE + NODE_TYPE_NAMES.UTILITY_NODE]:
    (NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] -
      NODE_SIZES[NODE_TYPE_NAMES.UTILITY_NODE]) /
    2,
  [NODE_TYPE_NAMES.AUTOMATION_NODE + NODE_TYPE_NAMES.AUTOMATION_NODE]: 0,
  [NODE_TYPE_NAMES.UTILITY_NODE + NODE_TYPE_NAMES.UTILITY_NODE]:
    NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] -
    NODE_SIZES[NODE_TYPE_NAMES.UTILITY_NODE]
}

export const EDGE_TYPE_NAMES = {
  CONNECTION_LINE: 'custom'
}

export const NEXT_NODE_CONNECTION_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}

export const WORKFLOW_CREATED_FROM = {
  BUILDER: 'builder',
  PUBLIC_WORKFLOW: 'public-workflow',
  STORE: 'automation-store',
  API: 'api',
  AUTOMATION_MATRIX: 'automation-matrix'
}

export const AUTOMATION_VARIABLE_TYPES = {
  TEXT: 'text',
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  SELECT: 'select',
  MESSAGE: 'message',
  OBJECT: 'object',
  DATE: 'date',
  TIME: 'time',
  MULTI_SELECT: 'multiSelect',
  DYNAMIC_SELECT: 'dynamicSelect',
  ATTACHMENT: 'attachment',
  OUTPUT_CHECKBOX: 'outputCheckbox',
  FILE: 'file',
  SN_MESSAGE: 'sn-message',
  GOOGLE_PICKER: 'googlePicker'
}

export const AUTOMATION_VARIABLE_DIRECTION = {
  INPUT: 'input',
  OUTPUT: 'output'
}

export const PLATFORM_AUTH_TYPES = {
  COOKIE: 'cookie',
  API_KEY: 'apiKey',
  OAUTH2: 'OAuth2'
}

export const UTILITY_TYPES = {
  ROUTER: 'routing',
  FILTER: 'filter',
  DELAY: 'delay',
  DEDUPE: 'dedupe',
  OUTGOINGWEBHOOK: 'outgoingwebhook',
  INCOMINGWEBHOOK: 'incomingwebhook'
}

export const EVENTS_TO_BE_TRACKED = {
  CREATED_FIRST_WORKSPACE: 'createdFirstWorkspace',
  MENU_OPTION_CLICKED: 'menuOptionClicked',
  CLICKED_CANCEL_SUBSCRIPTION_BUTTON: 'clickedCancelSubscriptionButton',
  CLICKED_UPGRADE_PLAN_BUTTON: 'changePlan',
  AUTOMATION_CLICKED: 'automationClicked'
}

export const VARIABLE_REGEX = /{{([A-z.\-0-9: ]+)}}/gi
export const TAG_REGEX = /\[\[([A-z0-9]+?)\]\]/gi
export const INPUT_UTILITY_REGEX = /%%([A-z0-9]+)\(([^%]*)\)%%/gi

export const WORKFLOW_STATUS = {
  RUNNING: 'running',
  PAUSED: 'paused',
  WAITING: 'waiting',
  DELAYED: 'delayed',
  COMPLETED: 'completed',
  FAILED: 'failed',
  PARTIALLY_FAILED: 'partially-failed'
}

export const platformIds = {
  '622f03eb770f6bba0b8facaa': 'Linkedin',
  '6307070b15bef68874a6a73c': 'Twitter'
}
