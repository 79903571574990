<template>
  <div
    class="absolute right-0 z-20 mt-1.5 w-96 divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white shadow-xl dark:divide-gray-700 dark:border-gray-700 dark:bg-gray-900"
  >
    <div class="py-1" role="none">
      <div v-if="loading" class="flex justify-center px-4 py-3">
        <Spinner />
      </div>
      <p v-if="noNotifications" class="px-4 py-3 text-center italic">
        No new notifications
      </p>
      <div v-if="!loading && !noNotifications">
        <div
          class="flex justify-between border-b border-gray-200 px-4 pb-1 pt-3"
        >
          <div>
            <p class="text-xl font-extrabold">Notifications</p>
            <p
              class="cursor-pointer text-sm underline"
              @click="
                onViewAllClick()
              "
            >
              View All
            </p>
          </div>

          <p
            class="flex cursor-pointer items-end justify-end text-sm font-bold underline"
            @click="markAllAsRead()"
          >
            Mark all as read
          </p>
        </div>
        <div
          @click="
            redirectToResults(
              notification.workflowExecutionId,
              notification.workflowId,
              notification.activityId,
              notification.notificationType
            )
          "
          v-for="notification of notifications"
          :key="notification.activityId"
          class="flex items-center justify-between space-x-8 whitespace-nowrap px-4 py-3 text-base font-medium text-gray-700 transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-800 sm:text-sm"
          :class="[
            { 'cursor-pointer': notification.notificationType === 'completed' }
          ]"
          role="menuitem"
          tabindex="-1"
        >
          <div class="min-w-0 flex-1">
            <p
              class="truncate text-sm font-semibold text-gray-900 dark:text-gray-50"
            >
              {{ notification.message }}
            </p>
            <p
              class="mt-0.5 text-xs font-normal text-gray-600 dark:text-gray-400"
            >
              {{
                `${formatDate(notification.createdAt)} ${formatTime(
                  notification.createdAt
                )}`
              }}
            </p>
          </div>

          <span
            class="inline-flex items-center rounded-lg px-3 py-1.5 text-sm font-semibold capitalize"
            :class="[
              {
                'bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-50':
                  completed(notification.notificationType),
                'bg-red-100 text-red-600 dark:bg-red-900 dark:text-red-50':
                  failed(notification.notificationType),
                'bg-yellow-100 text-yellow-500 dark:bg-yellow-700 dark:text-yellow-50':
                  update(notification.notificationType),
                'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-50':
                  paused(notification.notificationType)
              }
            ]"
          >
            {{ notification.notificationType }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { notificationTypes } from '../common/constants'
import { getAllActivities, removeActivities } from '../apis/activities'
import { mapActions, mapState } from 'vuex'
import Spinner from './Spinner.vue'
export default {
  name: 'NotificationDropdown',
  computed: {
    ...mapState('user', ['notificationNumber'])
  },
  methods: {
    ...mapActions('user', ['saveNotificationNumber']),
    completed(type) {
      return type === notificationTypes.COMPLETED
    },
    failed(type) {
      return type === notificationTypes.FAILED
    },
    paused(type) {
      return type === notificationTypes.PAUSED
    },
    update(type) {
      return type === notificationTypes.UPDATE
    },
    formatDate(d) {
      const newDate = new Date(Date.parse(d))

      const curr_date = newDate.getDate()

      const curr_month = newDate.toLocaleString('en-US', { month: 'short' })

      const curr_yr = newDate.getFullYear()
      return `${curr_date} ${curr_month} ${curr_yr}`
    },
    formatTime(d) {
      const newDate = new Date(Date.parse(d))
      let startTime = new Date(newDate.getTime())
      return startTime.toLocaleTimeString()
    },
    async markAllAsRead() {
      this.loading = true
      let id = []

      for (let notification of this.notifications) {
        id.push({
          id: notification.activityId
        })
      }
      let data = {
        ids: id,
        all: true,
        isRead: true,
        markedForDelete: true
      }

      await removeActivities(data)
      this.saveNotificationNumber({ payload: 0 })
      this.loading = false
      this.$emit('closeNotificaiton')
    },
    async redirectToResults(
      executionId,
      workflowId,
      activityId,
      notificationType
    ) {
      if (notificationType !== 'completed') {
        return
      }
      let data = {
        ids: [
          {
            id: activityId
          }
        ],
        isRead: true,
        markedForDelete: true
      }
      await removeActivities(data)
      this.saveNotificationNumber({ payload: this.notificationNumber - 1 })
      this.$emit('closeNotificaiton')
      this.$router.push(
        `/data-store/results?executionId=${executionId}&workflowId=${workflowId}`
      )
    },
    onViewAllClick(){
      this.$router.push('/notifications'),
       this.$emit('closeNotificaiton')
    }
  },
  data() {
    return {
      notifications: [],
      loading: true,
      noNotifications: false
    }
  },
  async mounted() {
    const data = await getAllActivities()
    if (data.data) {
      this.notifications = data.data
    }

    // if no notifications, show no notification message
    this.noNotifications = this.notifications.length === 0

    this.loading = false
  },
  components: { Spinner }
}
</script>
