<template>
  <div
    class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
  >
    <div class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6">
      <div v-if="loading" class="flex h-full items-center justify-center">
        <Spinner size="large" />
      </div>
      <div v-else class="grid grid-cols-2">
        <Schedulingmodal
          from="store"
          class="mb-2"
          @success="success"
          @error="error"
        />
      </div>

      <div class="mt-auto flex items-center justify-between">
        <Button
          color="tertiary"
          text="Previous"
          leftIcon="left-arrow"
          @click="prevButtonClick()"
        />
        <div>
          <p class="font-bold">
            Save your scheduling settings before clicking
            <span class="text-blue-600">Run</span>
          </p>
        </div>

        <Button
          v-if="isScheduled"
          :showLoader="buttonLoading"
          @click="stopSchedule()"
          text="Stop Schedule"
          color="danger"
          rightIcon="right-arrow"
        />
        <div v-else>
          <Button
            v-if="$route.query.source === 'input'"
            :showLoader="buttonLoading"
            :color="validationStatus ? 'successFilled' : 'gray'"
            :disabled="validationStatus ? false : true"
            @click="nextButtonClick()"
            :text="automationScheduleData ? 'Schedule' : 'Run'"
            rightIcon="right-arrow"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import Select from '../Select.vue'
import Scheduling from './Scheduling.vue'
import Schedulingmodal from '@/components/automationStore/SchedulingModal.vue'
import { pauseSchedule } from '@/apis/workflows'
import { WORKFLOW_CREATED_FROM } from '@/common/constants'
import { mapActions, mapState } from 'vuex'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'SchedulingMode',
  components: { Select, Button, Scheduling, Schedulingmodal, Spinner },
  data() {
    return {
      workflowId: null,
      nodeId: null,
      loading: false,
      buttonLoading: false
    }
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['automationScheduleData']),
    ...mapState('automationStore', ['automationInputs']),
    ...mapState('automationStore', ['workflowIdCreated']),
    ...mapState('automationStore', ['executionId']),
    ...mapState('automationStore', ['AutomationStatus']),
    ...mapState('automationStore', ['connectedAccountId']),
    ...mapState('automationStore', ['automationNameText']),
    ...mapState('automationStore', ['delayAutomationNaming']),
    ...mapState('automationStore', ['validationStatus'])
  },
  props: {
    isScheduled: {
      required: true
    }
  },
  methods: {
    ...mapActions('automationStore', ['addAutomationData']),
    ...mapActions('automationStore', ['toggleWorkflowIdCreated']),
    ...mapActions('automationStore', ['addExecutionId']),
    ...mapActions('automationStore', ['setDelayAutomationNaming']),

    async stopSchedule() {
      try {
        const response = await pauseSchedule(this.$route.query.workflowId)
        if (response['success']) {
          this.$emit('success', 'Automation Schedule Stopped Successfully')
          this.$emit('setScheduledStatus', false)
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
    error() {
      this.$emit(
        'error',
        'Failed to add or update the schedule. Please try again in some time.'
      )
    },
    success() {
      this.$emit('success', 'Schedule updated successfully')
    },
    nextButtonClick() {
      if (this.validationStatus === true) {
        this.buttonLoading = true
        this.$emit('AfterFormValidate')
      } else {
        this.$emit('nextButtonClick', {
          comp: 'run-comp',
          highlight: 4,
          optionalSidebarType: 'run',
          optionalSidebarItem: 'start',
          optionalSidebarSearch: true,
          startAutomation: true,
          runButtonOnHeader: true
        })
      }
    },
    prevButtonClick() {
      this.$emit('nextButtonClick', {
        comp: `${this.$route.query.source}-comp`,
        highlight: 1,
        optionalSidebarType: 'default',
        optionalSidebarItem: 'center',
        optionalSidebarSearch: false
      })
    }
  }
}
</script>

<style></style>
