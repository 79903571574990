export default {
  userData: {},
  userWorkspaceData: null,
  executionTimeLeft: null,
  emailVerifiersLeft: null,
  emailEnrichmentsLeft: null,
  notificationNumber: null,
  proxyAccess: null,
  isExpandedDefault: false,
  socialAccountsLeft: null,
  socialAccountsAllowed: null,
  desktopUnlimited: false,
  aiAccess: false,
  integrationsAccess: false,
  apiAccess: false,
  additionalData: null
}
