<template>
  <div v-if="loading" class="flex min-h-[60vh] items-center justify-center">
    <Spinner size="large" />
  </div>
  <div class="mx-auto mt-8 max-w-4xl space-y-8" v-else>
    <div class="flex items-center justify-center">
      <div
        class="relative inline-flex self-center overflow-hidden rounded-lg border border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900"
      >
        <!-- TODO: MIGHT NEED LATER -->
        <!-- <button
          @click="show = 'free'"
          type="button"
          class="duraiton-200 relative w-1/2 whitespace-nowrap py-2 text-sm sm:w-[20vh]"
          :class="[
            {
              'bg-blue-600 font-semibold text-white shadow-sm ': free
            },
            {
              'border border-transparent font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                yearly || monthly
            }
          ]"
        >
          Free
        </button> -->
        <button
          @click="show = 'monthly'"
          type="button"
          class="duraiton-200 relative whitespace-nowrap py-2 text-sm transition-all sm:w-[20vh] sm:px-8"
          :class="[
            {
              'bg-blue-600 font-semibold text-white shadow-sm': monthly
            },
            {
              'border-x border-gray-200 font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                yearly || free
            }
          ]"
        >
          Monthly
        </button>

        <button
          @click="show = 'yearly'"
          type="button"
          class="duraiton-200 relative whitespace-nowrap py-2 text-sm transition-all sm:w-[20vh] sm:px-8"
          :class="[
            {
              'bg-blue-600 font-semibold text-white shadow-sm ': yearly
            },
            {
              'border border-transparent font-medium text-gray-600  hover:bg-gray-100    dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                monthly || free
            }
          ]"
        >
          Yearly 20% Off
        </button>
      </div>
    </div>

    <div v-if="show === 'free'" class="grid grid-cols-3 gap-x-4">
      <div></div>
      <div
        class="overflow-hidden rounded-lg border border-transparent bg-blue-50 dark:bg-blue-900/50"
      >
        <div class="px-4 py-5 sm:p-6">
          <p
            class="text-sm font-semibold uppercase tracking-widest text-blue-600 dark:text-blue-400"
          >
            {{ freePlan[0].name }}
          </p>
          <div class="mt-5 flex items-start">
            <span class="text-xl font-semibold text-gray-900 dark:text-gray-50">
              $
            </span>
            <div class="ml-1 flex items-end">
              <p class="text-5xl font-bold text-gray-900 dark:text-gray-50">
                {{ freePlan[0].planType.monthly.price }}
              </p>
              <p class="text-xl font-semibold text-gray-900 dark:text-gray-50">
                /month
              </p>
            </div>
          </div>
          <p class="mt-3 text-sm text-gray-600 dark:text-gray-400">
            {{ freePlan[0].title }}
          </p>

          <div class="mt-8">
            <Button
              class="w-full"
              size="normal"
              :text="yourPlan(freePlan[0].isPlan)"
              color="primary"
              @click="
                startBilling(
                  freePlan[0]._id,
                  freePlan[0].planType.monthly.planPaymentId,
                  freePlan[0].name
                )
              "
            />
          </div>

          <ul class="mt-8 space-y-4 text-sm text-gray-600 dark:text-gray-400">
            <li
              v-for="(itemElement, indexElement) in freePlan[0].planDetails"
              class="flex items-center"
              :key="indexElement"
            >
              <SvgIcon
                class="h-5 w-5 mr-3 text-blue-500 dark:text-blue-400"
                name="done"
              />
              {{ itemElement }}
            </li>
          </ul>
        </div>
      </div>
      <div></div>
    </div>

    <div v-if="show !== 'free'" class="grid grid-cols-3 gap-x-4">
      <div
        v-for="(item, index) in reorderedPlans"
        class="overflow-hidden rounded-lg border border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900"
        :key="index"
      >
        <!-- TODO: WILL BE NEEDED LATER -->
        <!-- :class="[
              {
                'border-transparent bg-blue-50 dark:bg-blue-900/50':
                  item.yourPlan,
                'border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900':
                  !item.yourPlan
              }
            ]" -->
        <div class="px-4 py-5 sm:p-6">
          <p
            class="text-sm font-semibold uppercase tracking-widest text-blue-600 dark:text-blue-400"
          >
            {{ item.name }}
          </p>
          <div class="mt-5 flex items-start">
            <span class="text-xl font-semibold text-gray-900 dark:text-gray-50">
              $
            </span>
            <div class="ml-1 flex items-end">
              <p class="text-5xl font-bold text-gray-900 dark:text-gray-50">
                {{
                  show === 'monthly'
                    ? item.planType.monthly.price
                    : item.planType.yearly.price
                }}
              </p>
              <p class="text-xl font-semibold text-gray-900 dark:text-gray-50">
                {{ show === 'monthly' ? '/month' : '/year' }}
              </p>
            </div>
          </div>
          <p class="mt-3 text-sm text-gray-600 dark:text-gray-400">
            {{ item.title }}
          </p>

          <div class="mt-8">
            <Button
              class="w-full"
              size="normal"
              :text="
                show === 'monthly'
                  ? yourPlan(item.planType.monthly.isPlan)
                  : yourPlan(item.planType.yearly.isPlan)
              "
              :color="
                show === 'monthly'
                  ? yourPlanColor(item.planType.monthly.isPlan)
                  : yourPlanColor(item.planType.yearly.isPlan)
              "
              @click="startBilling(item._id, item.planType, item.name)"
            />
            <!-- TODO: WILL BE NEEDED LATER -->
            <!-- :text="item.yourPlan ? 'Your Plan' : 'Upgrade'"
                  :color="item.yourPlan ? 'primary' : 'secondary'" -->
          </div>

          <ul class="mt-8 space-y-4 text-sm text-gray-600 dark:text-gray-400">
            <li
              v-for="(itemElement, indexElement) in item.planDetails"
              class="flex items-center"
              :key="indexElement"
            >
              <SvgIcon
                class="h-5 w-5 mr-3 text-blue-500 dark:text-blue-400"
                name="done"
              />
              {{ itemElement }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="overflow-hidden rounded-lg border border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:p-6">
        <div class="flex items-center justify-between space-x-6">
          <div class="flex-1">
            <p class="text-2xl font-bold text-gray-900 dark:text-gray-50">
              Want to see more?
            </p>
            <p
              class="mt-1 text-base font-medium text-gray-600 dark:text-gray-400"
            >
              Contact our sales team for a more customized pricing
            </p>
          </div>

          <div class="shrink-0">
            <Button size="normal" text="Contact Sales" color="primary" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlans, updatePlan } from '@/apis/settings/settings-billing'
import { getBaseUser } from '@/apis/user'
import { EVENTS_TO_BE_TRACKED } from '@/common/constants'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import BillingHeader from '@/components/settings/billingHeader.vue'
import checkOutModal from '@/components/settings/checkOutModal.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'planSection',
  components: {
    BillingHeader,
    Button,
    checkOutModal,
    SvgIcon,
    Spinner
  },
  props: {
    isAlreadySubscribed: { type: String, required: true },
    status: { type: String, required: true }
  },
  data() {
    return {
      show: 'monthly',
      freePlan: [],
      regularPlans: [],
      loading: true
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    monthly() {
      return this.show === 'monthly'
    },
    yearly() {
      return this.show === 'yearly'
    },
    free() {
      return this.show === 'free'
    },

    reorderedPlans() {
      const arr = this.regularPlans
      return [arr[0], arr[2], arr[1]]
    }
  },
  created() {
    this.getAllPlans()
  },
  methods: {
    ...mapActions('user', ['saveUserData']),
    async getAllPlans() {
      try {
        const baseUserResponse = await getBaseUser()
        this.saveUserData({ payload: baseUserResponse.data })
        let planId = baseUserResponse.data.planId
        let planPaymentId = baseUserResponse.data.planPaymentId

        const response = await getPlans()
        let data = response.data
        for (let ele of data) {
          if (ele.planType.freePlan === true) {
            this.freePlan.push(ele)
            if (ele._id === planId) {
              this.freePlan[0].isPlan = true
            }
          } else {
            if (ele._id === planId) {
              let newData = ele
              for (const data in newData.planType) {
                if (newData.planType[data].planPaymentId === planPaymentId) {
                  newData.planType[data].isPlan = true

                  this.regularPlans.push(newData)
                }
              }
            } else {
              this.regularPlans.push(ele)
            }
          }
        }

        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },

    async startBilling(id, type, planName) {
      try {
        let newPlanPaymentId
        let response

        if (this.show === 'free') {
          newPlanPaymentId = type
        } else {
          newPlanPaymentId = type[this.show].planPaymentId
        }

        if (this.isAlreadySubscribed && this.status !== 'deleted') {
          this.loading = true

          // if user has paused the plan
          if (this.userData.isPaused) {
            this.$emit('planEmit', {
              type: 'warning',
              msg: `Please Resume Your Subscription First Before Upgrading Plans`
            })
            this.loading = false
            return
          }

          response = await updatePlan(id, newPlanPaymentId)
          if (response['success']) {
            amplitudeTrackEvent(
              EVENTS_TO_BE_TRACKED.CLICKED_UPGRADE_PLAN_BUTTON,
              localStorage.getItem('email'),
              { planName }
            )
            this.freePlan = []
            this.regularPlans = []

            this.getAllPlans()
            this.$emit('planEmit', {
              type: 'success',
              msg: `Plan Updated Successfully`
            })
          } else {
            this.$emit('planEmit', {
              type: 'fail',
              msg: `Some Error Occured Please Try Again Later ...`
            })
          }
        } else {
          if (this.show === 'free') {
            return
          }
          this.$emit('showCheckoutModal', {
            planId: id,
            planPaymentId: newPlanPaymentId
          })
        }
      } catch (error) {
        this.loading = false
        console.log(error)
        this.$emit('planEmit', {
          type: 'fail',
          msg: `Some Error Occured Please Try Again Later ...`
        })
      }
      this.loading = false
    },

    yourPlan(isPlan) {
      if (isPlan) {
        return 'Your Plan'
      } else return 'Upgrade'
    },
    yourPlanColor(isPlan) {
      if (isPlan) {
        return 'primary'
      } else return 'secondary'
    }
  }
}
</script>

<style scoped></style>
