<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="">
          <div class="flex items-center justify-between">
            <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
              Google Sheets
            </p>
            <Banner
              text="Please ensure that pop-ups are enabled in your browser settings to allow the Google Sheet to open."
              :showClose="false"
            />
            <div class="flex items-center justify-end space-x-4">
              <ModalCloseButton @click="emitClose" />
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6">
        <div
          v-if="loading"
          class="flex min-h-[40vh] items-center justify-center"
        >
          <Spinner size="large" />
        </div>
        <div v-else class="min-h-full p-4">
          <div class="flex flex-col">
            <div class="flex justify-between">
              <div class="">
                <p class="py-2 font-semibold text-gray-900">
                  Select Google Account
                </p>
                <div class="w-[30rem]">
                  <Select :options="choices" v-model="accountId" />
                </div>
              </div>

              <div v-if="userRole !== 'viewer'">
                <p class="py-2 font-semibold text-gray-900">
                  Add New Google Sheet Account:
                </p>

                <img
                  @click="addOauthAccount()"
                  class="h-[46px] cursor-pointer"
                  src="@/assets/images/logos/btn_google_signin_dark_normal_web@2x.png"
                  alt="signin-with-google"
                />
              </div>
            </div>
            <div class="col-span-2">
              <p class="py-2 font-semibold text-gray-900">
                Select {{ fromBuilder ? 'Steps' : 'Columns' }}
              </p>
              <div
                class="mt-1.5 max-h-[400px] min-h-[100px] flex flex-wrap gap-4 overflow-y-scroll px-3"
              >
                <div class="w-[250px]">
                  <Checkboxes
                    label="All"
                    v-model="allCheckboxTick"
                    @click="event => onAllClick(event)"
                  />
                </div>
                <div class="w-[250px]" v-for="data of listData" :key="data._id">
                  <Checkboxes
                    :modelValue="isAllClicked"
                    @click="event => onClick(event, data.label, data._id)"
                    :label="data.label"
                  />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div class="flex flex-row-reverse">
          <Button text="Export" :showLoader="buttonLoader" @click="onSave()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Select from '../Select.vue'
import Spinner from '@/components/Spinner.vue'
import { mapActions, mapState } from 'vuex'
const googlePlatformId = import.meta.env.VITE_GOOGLE_SHEETS_PLATFORMID
import { WORKFLOW_CREATED_FROM } from '@/common/constants'
//api
import { getConnectedAccounts } from '@/apis/automation-store/Page1'
import { openResultsInGoogleSheets } from '@/apis/automation-store/googleSheets'
import {
  getWorkflow,
  getWorkflowAllNodes,
  getWorkflowNode
} from '@/apis/workflows'
import { getOperationVariableServices } from '@/apis/getPlatformDetails'
import Checkboxes from '../Checkboxes.vue'
import { startOauthConnection } from '@/apis/oauth'
import ModalCloseButton from '@/components/ModalCloseButton.vue'
import Banner from '../Banner.vue'

export default {
  name: 'GoogleSheetsModal',
  data() {
    return {
      choices: null,
      loading: true,
      accountId: null,
      labelArray: [],
      isAllClicked: true,
      allCheckboxTick: true,
      buttonLoader: false,
      listData: null,
      fromBuilder: false,
      channel: null
    }
  },
  components: {
    Button,
    SvgIcon,
    Input,
    Spinner,
    Select,
    Checkboxes,
    ModalCloseButton,
    Banner
  },
  async mounted() {
    try {
      //get the google sheets account
      const response = await getConnectedAccounts(googlePlatformId)
      this.choices = response.data.map(account => ({
        value: account._id,
        label: account.name
      }))
      if (this.choices.length > 0) this.accountId = this.choices[0].value

      //check if output variables are stored in vuex if not call api to get output variables
      if (!!!this.automationStoreData.outputList) {
        const response = await getWorkflow(this.modalData.workflowId)
        const startNode = response.data.startNode
        let nodeId, platformId, operationId
        if (response.data.createdFrom === WORKFLOW_CREATED_FROM.BUILDER) {
          const response = await getWorkflowAllNodes(this.modalData.workflowId)
          this.listData = response.data
          this.fromBuilder = true
        } else {
          if (
            this.modalData.inputSource === 'sheet' ||
            this.modalData.inputSource === 'csv'
          ) {
            const response = await getWorkflowAllNodes(
              this.modalData.workflowId
            )
            ;({ nodeId, operationId, platformId } = this.parseResponse(
              response.data[1]
            ))
          } else {
            const response = await getWorkflowNode(
              this.modalData.workflowId,
              startNode
            )
            ;({ nodeId, operationId, platformId } = this.parseResponse(
              response.data
            ))
          }
          const variableResponse = await getOperationVariableServices(
            platformId,
            operationId,
            'output',
            null,
            null
          )
          //store output variables in an array to show as checkboxes
          this.listData = variableResponse.data
        }
      } else {
        //store output variables in an array to show as checkboxes
        this.listData = this.automationStoreData.outputList
      }
      this.refillArray()
    } catch (error) {
      this.$emit('error', error)
    } finally {
      this.loading = false
    }
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('settings', ['userRole'])
  },
  props: {
    modalData: { type: Object }
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
    async onClick(e, label, id) {
      //will uncheck the all checkbox if it was checked earlier
      this.allCheckboxTick = false

      //if checked a checkbox the label or node (for workflowbuilder) gets pushed to array
      if (e.target.checked) {
        if (this.fromBuilder) {
          this.labelArray.push(id)
        } else {
          this.labelArray.push(label)
        }
      } else {
        //unchecking will remove that node or label from array
        if (this.fromBuilder) {
          this.labelArray = this.labelArray.filter(data => data !== id)
        } else {
          this.labelArray = this.labelArray.filter(data => data !== label)
        }
      }
    },
    onAllClick(e) {
      if (e.target.checked) {
        //will check all the checkbox available
        this.allCheckboxTick = true
        this.isAllClicked = true

        this.refillArray()
      } else {
        //uncheck all the checkboxes and reset the array
        this.allCheckboxTick = false
        this.isAllClicked = false
        this.labelArray = []
      }
    },

    refillArray() {
      //reset the array
      this.labelArray = []
      if (this.fromBuilder) {
        this.labelArray = this.listData.map(data => data._id)
      } else {
        this.labelArray = this.listData.map(data => data.label)
      }
    },
    async onSave() {
      this.buttonLoader = true
      try {
        if (!this.accountId) {
          throw 'Please Select Your Google Account Id'
        }

        let data = {
          executionId:
            this.$route.query.executionId ?? this.modalData.executionId,
          connectedAccountId: this.accountId
        }
        // try {
        this.fromBuilder
          ? (data['nodeIds'] = this.labelArray)
          : (data['columns'] = this.labelArray)

        const response = await openResultsInGoogleSheets(data)

        if (response['success']) {
          window.open(response.data.sheetUrl, '_blank')
        } else {
          throw response.message
        }
        // } catch (error) {
        //   console.log(error)
        // }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.buttonLoader = false
      }
    },
    parseResponse(data) {
      return {
        nodeId: data._id,
        operationId: data.platformOperationId,
        platformId: data.platformId
      }
    },
    async addOauthAccount() {
      const response = await startOauthConnection('google-sheets')

      if (response['success']) {
        window.open(response.data.url, '_blank')
        this.emitClose()
      } else {
        this.$emit('error', 'Error in adding new google account')
      }
    }
  }
}
</script>
