<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen items-stretch justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-3xl flex-col">
              <a href="#" title="TexAu" class="">
                <img
                  class="mx-auto h-12 w-auto xl:h-14"
                  src="@/assets/images/logo-alt.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Congratulations 🎉" />
                    <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                      We have successfully imported your accounts. Here are all
                      the accounts we found.
                    </p>
                  </div>
                  <div
                    class="mt-12 flex w-full flex-col items-center justify-center space-y-4"
                  >
                    <Spinner v-if="isLoading" size="large" />
                  </div>
                  <div class="mt-12 grid grid-cols-1 gap-5 sm:grid-cols-2">
                    <OnboardingCards
                      v-if="!isLoading"
                      v-for="data in cardData"
                      :key="data.name"
                      v-bind="data"
                    />
                  </div>

                  <div class="mt-6">
                    <Button
                      v-if="!isLoading && loadedSuccessfully"
                      @click="onNextClick"
                      class="w-full sm:w-auto"
                      type="submit"
                      text="Jump To Your Workspace"
                      rightIcon="right-arrow"
                      :showLoader="buttonLoading"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
    <ToastContainer class="w-1/5" ref="toast" />
  </div>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Heading from '@/components/onboarding/Heading.vue'
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import OnboardingCards from '@/components/onboarding/OnboardingCards.vue'
import { getConnectedAccountsUi } from '@/apis/connectedAccounts'
import ToastContainer from '@/components/ToastContainer.vue'
import { updateUserDetail } from '@/apis/user'
import UserProfile from '@/common/userWrapper'

export default {
  // step-5
  name: 'showAllImportedAccounts',
  components: {
    OnboardingLeftColumn,
    Heading,
    Button,
    OnboardingCards,
    Spinner,
    ToastContainer
  },
  data() {
    return {
      isLoading: false,
      loadedSuccessfully: false,
      cardData: [],
      buttonLoading: false
    }
  },
  methods: {
    // TODO: Will need later
    // async updateNextStep() {
    //   const responseUser = await updateUserDetail({
    //     currentOnboardingStep: 'Cloud and Desktop'
    //   })
    //   UserProfile.updateOnboardingStateInCookies('Cloud and Desktop')
    //   this.isLoading = false
    //   if (responseUser['success']) {
    //     await this.$router.push('/onboarding/cloud-and-desktop')
    //   } else {
    //     this.$refs.toast.addToast({
    //       timeout: 7000,
    //       text: 'Error Occurred!',
    //       color: 'red',
    //       caption: 'Error in moving to next step.',
    //       action: false,
    //       close: true
    //     })
    //   }
    // },
    async updateNextStep() {
      const responseUser = await updateUserDetail({
        isOnboarded: true,
        currentOnboardingStep: null
      })
      let user_ = UserProfile.getUser()
      user_['isOnboarded'] = true
      delete user_['currentOnboardingStep']
      UserProfile.setUser(user_)
      this.buttonLoading = false
      if (responseUser['success']) {
        await this.$router.go()
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: 'Error in moving to next step.',
          action: false,
          close: true
        })
      }
    },

    async getAllConnectedAccountData() {
      let data = []
      this.isLoading = true
      const response = await getConnectedAccountsUi()
      this.isLoading = false
      if (response['success']) {
        for (const responseElement of response.data) {
          data.push({
            userProfileIconUrl: responseElement?.picture
              ? responseElement?.picture
              : '',
            name: responseElement.name,
            desc: responseElement.description
              ? responseElement.description
              : '',
            platformIconUrl: responseElement?.platformIcon
              ? responseElement?.platformIcon
              : '',
            platformId: responseElement?.platformIds
              ? responseElement?.platformIds
              : '',
            isPremium: responseElement?.isPremium
              ? responseElement?.isPremium
              : false
          })
        }
        this.cardData = data
        this.loadedSuccessfully = true
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: response.message,
          action: false,
          close: true
        })
      }
    },

    async onNextClick() {
      this.buttonLoading = true
      await this.updateNextStep()
    }
  },
  beforeMount() {
    this.getAllConnectedAccountData()
  }
}
</script>

<style></style>
