<template>
  <div class="h-full w-60 shrink-0 overflow-y-auto">
    <div
      v-if="!userData.isPhoneVerified || !userData.isEmailVerified"
      class="flex items-center justify-center mt-2 px-4"
    >
      <router-link
        to="/settings-verify"
        title="Verify Account"
        class="rounded-lg text-center w-full px-3 bg-blue-500 text-white py-2 text-sm font-medium transition-all duration-200"
        @click.prevent="$router.push('/settings-verify')"
      >
        Verify Account
      </router-link>
    </div>

    <nav class="mt-5 flex justify-center space-x-4" aria-label="Tabs">
      <a
        href="#"
        title=""
        class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
        :class="[
          {
            'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
              tab === 'workspace'
          },
          {
            'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
              tab !== 'workspace'
          }
        ]"
        aria-current="page"
        @click.prevent="
          () => {
            tab = 'workspace'
            $router.push('/settings-user-permissions/user-roles')
          }
        "
      >
        Workspace
      </a>

      <a
        href="#"
        title=""
        class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
        :class="[
          {
            'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
              tab === 'organization'
          },
          {
            'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
              tab !== 'organization'
          }
        ]"
        @click.prevent="
          () => {
            tab = 'organization'
            $router.push('/settings-user-permissions/user-roles-organization')
          }
        "
      >
        Organization
      </a>
    </nav>
    <div class="flex flex-1 flex-col px-4 pt-2 pb-5 sm:px-6 sm:pb-6 sm:pt-2">
      <!-- <div class="flex flex-1 flex-col px-4 py-5 sm:px-5"> -->
      <nav
        v-if="tab === 'workspace'"
        class="-mx-2 flex flex-1 flex-col space-y-1.5"
        aria-label="Sidebar"
      >
        <SideMenuOption
          :is-active="isUsersActive"
          option-name="Users"
          @click.native="$router.push('/settings-user-permissions/user-roles')"
        />

        <SideMenuOption
          v-if="userData.developer"
          option-name="Tags"
          :isActive="isTagsActive"
          @click.native="$router.push('/settings-tags/tags')"
        />
        <SideMenuOption
          :is-active="isNotificationsActive"
          option-name="Notifications"
          @click.native="$router.push('/settings-notifications/notifications')"
        />
        <SideMenuOption
          :is-active="isIntegrationActive"
          option-name="Integrations"
          @click="
            () => {
              $emit('showIntegrations', 'integration')
              $router.push('/settings-integrations/all-integrations')
            }
          "
        />
        <!-- <SideMenuOption
        :is-active="isMessageTemplatesActive"
        option-name="Message Templates"
        @click.native="
          $router.push('/settings-message-templates/create-template')
        "
      />
      <SideMenuOption
        :is-active="isScheduleTemplatesActive"
        option-name="Schedule Templates"
        @click.native="
          $router.push('/settings-schedule-templates/schedule-templates')
        "
      /> -->
        <SideMenuOption
          :is-active="isProxiesActive"
          option-name="Proxies"
          @click.native="$router.push('/settings-proxies')"
        />
        <SideMenuOption
          :is-active="isApiActive"
          option-name="API"
          @click.native="$router.push('/settings-api-key/developer-api')"
        />

        <SideMenuOption
          :is-active="isDesktopActive"
          option-name="Desktop"
          @click.native="$router.push('/settings-desktop')"
        />
      </nav>
      <nav
        v-if="tab === 'organization'"
        class="-mx-2 flex flex-1 flex-col space-y-1.5"
        aria-label="Sidebar"
      >
        <SideMenuOption
          :is-active="isUsersOrganizationActive"
          option-name="Users"
          @click.native="
            $router.push('/settings-user-permissions/user-roles-organization')
          "
        />
        <SideMenuOption
          :is-active="isWorkspaceActive"
          option-name="Workspace"
          @click.native="$router.push('/settings-workspace')"
        />
        <SideMenuOption
          :is-active="isSecurityActive"
          option-name="Security"
          @click.native="$router.push('/settings-security')"
        />
        <SideMenuOption
          :is-active="ispartnersProgramActive"
          option-name="Partners Program"
          @click.native="$router.push('/settings-affiliate')"
        />
        <SideMenuOption
          :is-active="isSubscriptionAndBillingActive"
          option-name="Subscription & Billing"
          @click.native="$router.push('/settings-billing/plan')"
        />
      </nav>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import SideMenuOption from '@/components/settings/sideMenuOption.vue'
import { mapState } from 'vuex'

export default {
  name: 'settingsMenu',
  props: {
    activeOption: { type: String, required: true }
  },
  components: {
    SideMenuOption
  },
  created() {
    switch (this.activeOption) {
      case 'workspace' || 'Workspace':
        this.tab = 'organization'
        break
      case 'users' || 'Users':
        this.tab = 'workspace'
        break
      case 'usersOrganization' || 'UsersOrganization':
        this.tab = 'organization'
        break
      case 'tags' || 'Tags':
        this.tab = 'workspace'
        break
      case 'notifications' || 'Notifications':
        this.tab = 'workspace'
        break
      case 'integration' || 'Integration':
        this.tab = 'workspace'
        break
      case 'proxies' || 'Proxies':
        this.tab = 'workspace'
        break
      case 'api' || 'Api':
        this.tab = 'workspace'
        break
      case 'Subscription and Billing' || 'subscription and billing':
        this.tab = 'organization'
        break
      case 'security' || 'Security':
        this.tab = 'organization'
        break
      case 'Desktop' || 'desktop':
        this.tab = 'workspace'
        break
      case 'Partners Program' || 'partners program':
        this.tab = 'organization'
        break

      default:
        this.tab = 'workspace'
        break
        break
    }
  },
  data() {
    return {
      tab: 'workspace'
    }
  },

  computed: {
    ...mapState('user', ['userData']),
    isWorkspaceActive() {
      return (
        this.activeOption === 'workspace' || this.activeOption === 'Workspace'
      )
    },
    isUsersActive() {
      return this.activeOption === 'users' || this.activeOption === 'Users'
    },

    isUsersOrganizationActive() {
      return (
        this.activeOption === 'usersOrganization' ||
        this.activeOption === 'UsersOrganization'
      )
    },
    isTagsActive() {
      return this.activeOption === 'tags' || this.activeOption === 'Tags'
    },
    isNotificationsActive() {
      return (
        this.activeOption === 'notifications' ||
        this.activeOption === 'Notifications'
      )
    },
    isIntegrationActive() {
      return (
        this.activeOption === 'integration' ||
        this.activeOption === 'Integration'
      )
    },
    isMessageTemplatesActive() {
      return (
        this.activeOption === 'message templates' ||
        this.activeOption === 'Message Templates'
      )
    },
    isScheduleTemplatesActive() {
      return (
        this.activeOption === 'schedule templates' ||
        this.activeOption === 'Schedule Templates'
      )
    },
    isProxiesActive() {
      return this.activeOption === 'proxies' || this.activeOption === 'Proxies'
    },
    isApiActive() {
      return this.activeOption === 'api' || this.activeOption === 'Api'
    },
    isSubscriptionAndBillingActive() {
      return (
        this.activeOption === 'Subscription and Billing' ||
        this.activeOption === 'subscription and billing'
      )
    },
    isSecurityActive() {
      return (
        this.activeOption === 'Security' || this.activeOption === 'security'
      )
    },
    isDesktopActive() {
      return this.activeOption === 'Desktop' || this.activeOption === 'desktop'
    },
    isAgencyWhitelabelActive() {
      return (
        this.activeOption === 'Agency Whitelabel' ||
        this.activeOption === 'agency whitelabel'
      )
    },
    ispartnersProgramActive() {
      return (
        this.activeOption === 'Partners Program' ||
        this.activeOption === 'partners program'
      )
    }
  }
}
</script>

<style scoped></style>
