<template>
  <div
    role="region"
    class="overflow-hidden border border-gray-200 bg-white dark:border-gray-700"
    @keyup.delete.stop
  >
    <div
      :aria-expanded="expanded"
      class="flex w-auto items-center bg-yellow-200 justify-between px-2 py-2.5"
    >
      <div
        ref="nameComponent"
        class="select-all rounded px-1 text-gray-900 outline-1 dark:text-gray-50"
        :class="{
          'font-light italic': !modelValue.name,
          'hover:cursor-text hover:outline-dashed focus:outline-dashed':
            !readonly,
          'focus:outline-none': readonly
        }"
        :contenteditable="!readonly"
        @blur="onChange('name', $event.target.innerText)"
        @click="modelValue.name || selectAllText()"
        tabindex="1"
        @keyup.delete.stop
      >
        {{ modelValue.name || 'Your Workflow Name' }}
      </div>

      <span aria-hidden="true" class="ml-4" @click="onClick">
        <SvgIcon
          :name="expanded ? 'plus' : 'notes'"
          class="h-6 w-6 text-yellow-400 cursor-pointer transition-transform"
          :class="{
            'rotate-45': expanded,
            'rotate-0': !expanded
          }"
        />
      </span>
    </div>

    <div
      :style="{
        transitionProperty: 'height',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '0.25s'
      }"
      ref="expandableBody"
      class="bg-yellow-100"
    >
      <div class="h-full w-full px-3 pt-1 pb-5 text-sm">
        <textarea
          :value="modelValue.notes"
          @blur="onChange('notes', $event.target.value)"
          class="w-full no-scrollbar text-sm text-gray-900 p-0 resize-none bg-yellow-100 dark:text-gray-50 border-0 focus:outline-none focus:ring-0"
          name=""
          id=""
          rows="5"
          placeholder="Your workflow related notes..."
          :disabled="readonly"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SvgIcon from '../SvgIcon.vue'
import TextArea from '../TextArea.vue'

const props = defineProps({
  modelValue: {},
  readonly: Boolean
})

const expanded = ref(true)
const expandableBody = ref(null)
const nameComponent = ref()

const emit = defineEmits(['update:modelValue', 'textUpdate'])

onMounted(() => {
  setBodyHeight()
})

const onClick = () => {
  expanded.value = !expanded.value
  setBodyHeight()
}

const setBodyHeight = () => {
  expandableBody.value.style.height = expanded.value
    ? `${expandableBody.value.scrollHeight}px`
    : '0px'
}

const onChange = (field, value) => {
  if (value === '' && field === 'name') {
    nameComponent.value.innerText = props.modelValue.name
    return
  }
  emit('update:modelValue', { ...props.modelValue, [field]: value }),
    emit('textUpdate', { ...props.modelValue, [field]: value })
}

const selectAllText = () => {
  nameComponent.value.focus()
  document.execCommand('selectAll', false, null)
}
</script>
