<script>
import { getBaseUser, getUsers } from '@/apis/user'
import { getAllActivities } from '@/apis/activities'
import UserProfile from '@/common/userWrapper'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutGeneric from '@/layouts/LayoutGeneric.vue'
import { mapActions, mapState } from 'vuex'
import Spinner from './components/Spinner.vue'
import { H } from 'highlight.run'
import { getExecutionTimeLeft } from '@/common/functions/executionTimeFetch'
export default {
  name: 'App',
  components: { LayoutDefault, LayoutGeneric, Spinner },
  async created() {
    if (UserProfile.getUser()) {
      await this.getBaseUser()
    }

    // TODO: will be needed when adding dark mode

    // if (!localStorage.getItem('theme')) {
    //   if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //     localStorage.setItem('theme', 'dark')
    //     document.documentElement.classList.add('dark')
    //   } else {
    //     localStorage.setItem('theme', 'light')
    //     document.documentElement.classList.remove('dark')
    //   }
    // } else {
    //   if (localStorage.getItem('theme') === 'dark') {
    //     document.documentElement.classList.add('dark')
    //   } else {
    //     document.documentElement.classList.remove('dark')
    //   }
    // }

    //check if user data(auth user) is available or not
    if (Object.keys(this.userData).length > 0) {
      H.identify(this.userData.email, {
        firstName: this.userData.firstname,
        lastName: this.userData.lastname,
        organisationId: window.$cookies.get('organisationId'),
        orgUserId: window.$cookies.get('orgUserId'),
        workspaceId: window.$cookies.get('workspaceId')
      })

      window.clarity(
        'identify',
        this.userData.email,
        '',
        this.$route.params?.id
      )
    } else {
      try {
        //set ip address as identifier
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        H.identify(data.ip, {})
      } catch (error) {
        console.error('Error:', error)
      }
    }

    this.loading = false
  },

  data() {
    return {
      loading: true
    }
  },

  computed: {
    showLayout() {
      return this.$route.meta.layout !== false
    },
    ...mapState('user', ['userData'])
  },
  methods: {
    ...mapActions('user', ['saveUserData']),
    ...mapActions('user', ['saveNotificationNumber']),
    ...mapActions('user', ['saveWorkspaceData']),

    async getBaseUser() {
      const [baseUserResponse, activityResponse, userResponse] =
        await Promise.all([
          getBaseUser(),
          getAllActivities(),
          getUsers(),
          getExecutionTimeLeft()
        ])
      if (baseUserResponse['success']) {
        this.saveUserData({ payload: baseUserResponse.data })
      } else {
        return 'Unable to fetch your account data.'
      }
      if (activityResponse['success']) {
        this.saveNotificationNumber({ payload: activityResponse.total })
      }
      if (userResponse['success']) {
        this.saveWorkspaceData({ payload: userResponse.data })
      }
    },

    checkUser() {
      return !!UserProfile.getUser()
    },
    checkOnboarded() {
      const user = UserProfile.getUser()
      return user.isOnboarded
    }
  }
}
</script>

<template>
  <!-- <div>
    <div>
      <header class="bg-white shadow" v-if="$route.meta.title">
        <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight text-gray-900">
            {{ $route.meta.title }}
          </h1>
        </div>
      </header>
      <main>
        <router-view />
      </main>
    </div>
  </div> -->
  <div class="flex h-full w-full items-center justify-center" v-if="loading">
    <Spinner size="large" />
  </div>
  <layout-default
    v-else-if="checkUser() && checkOnboarded() && showLayout"
    v-slot="scope"
  >
    <router-view
      @error="scope.error"
      @success="scope.success"
      @warning="scope.warning"
    />
  </layout-default>

  <layout-generic v-else v-slot="scope">
    <router-view
      @error="scope.error"
      @success="scope.success"
      @warning="scope.warning"
    />
  </layout-generic>
</template>
