div
<template>
  <AutomationHeader title="Data Store" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <ForceDeleteWorkfowModal
      :forceDeleteData="workflowIdToDelete"
      v-if="deleteWorkflowModal"
      @close="deleteWorkflowModal = false"
      @success="bulkDeleteWorkflowSuccess"
      @error="
        e => {
          $emit('error', e)
        }
      "
    />

    <CloneWorkflowModal
      v-if="showCloneWorkflowModal"
      :cloneWorkflowId="cloneWorkflowId"
      @close="showCloneWorkflowModal = false"
      @successCloneWorkflow="successCloneWorkflow"
      @error="
        e => {
          $emit('error', e)
        }
      "
    />

    <resetWorkflowModal
      :modalData="modalData"
      v-if="resetWorkflowModal"
      @close="resetWorkflowModal = false"
      @resetSuccess="e => $emit('success', e)"
      @resetFail="e => $emit('fail', e)"
    />
    <div class="flex h-full min-w-0 flex-1 flex-col px-4 py-5 sm:p-6">
      <div class="">
        <div class="mt-2 flex items-center space-x-4">
          <div class="flex-1">
            <label for="" class="sr-only"> Search recipe </label>
            <div class="relative mt-[26px]">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400 dark:text-gray-50"
              >
                <SvgIcon name="search" />
              </div>

              <input
                v-debounce:300ms="fetchOptions"
                type="text"
                id="search-input"
                class="block w-full rounded-lg border border-gray-300 py-2.5 pl-10 pr-10 placeholder-gray-500 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                placeholder="Search workflows"
                :value="$route.query.q"
                @input="
                  e =>
                    updateQuery({
                      page: 1,
                      q: e.target.value
                    })
                "
              />
            </div>
          </div>
          <div>
            <Select
              :modelValue="$route.query.createdFrom"
              @update:modelValue="onFilterCreated"
              :options="createdFromOptions"
              class="min-w-[10rem]"
              hideClear
              label="Filter By Store"
            />
          </div>
          <div>
            <Select
              :modelValue="$route.query.status"
              @update:modelValue="onFilterStatus"
              :options="statusOptions"
              class="min-w-[10rem]"
              hideClear
              label="Filter By Status"
            />
          </div>
          <div>
            <Select
              :left-image-url="accountIcon"
              :modelValue="$route.query.connectedAccountId"
              @update:modelValue="onFilterAccount"
              :options="accountOptions"
              class="min-w-[10rem]"
              hideClear
              label="Filter By Account"
              @change="setPlatformSelectIcon"
            />
          </div>
          <div>
            <Select
              :left-image-url="platformIcon"
              :modelValue="$route.query.platformId"
              @update:modelValue="onFilterPlatform"
              :options="platformOptions"
              class="min-w-[10rem]"
              hideClear
              label="Filter By Platform"
            />
          </div>
          <div class="mt-[26px]">
            <button
              type="button"
              class="inline-flex items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2.5 text-base font-semibold text-white transition-all duration-200 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 sm:text-sm"
              @click="$router.push('/workflow-builder')"
            >
              <SvgIcon name="plus" class="-ml-1 mr-2 h-5 w-5" />
              New Workflow
            </button>
          </div>
          <div class="mt-[26px]">
            <Button
              color="iconOnly"
              class="-m-2 p-2 text-gray-900 bg-gray-200 cursor-not-allowed pointer-events-none"
              :class="[
                {
                  'text-red-600 bg-red-50 cursor-pointer pointer-events-auto ':
                    workflowIdToDelete.length > 0
                }
              ]"
              icon="delete"
              @click="deleteWorkflowModal = true"
            />
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="mt-8 flex h-full flex-col overflow-y-auto overflow-x-auto"
      >
        <DataStoreSkeletonLoader :columns="getColumns" />
      </div>

      <!-- Show when data is empty -->
      <div
        v-if="showEmptyData() && !loading"
        class="mt-10 flex h-full w-full flex-col items-center justify-center space-y-4"
      >
        <SvgIcon :name="emptyData" />
        <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
          No Data Available
        </p>
      </div>

      <!-- show when not loading and data is fetched -->
      <div
        v-if="!loading && !showEmptyData()"
        class="mt-8 flex h-full flex-col overflow-y-auto overflow-x-auto"
      >
        <div class="h-full">
          <div class="inline-block h-full min-w-full py-2 align-middle">
            <Table
              :key="rerender"
              :selected="selected"
              :columns="getColumns"
              :data="workflows"
              :dataResults="workflowResults"
              :deleteResults="deleteResults"
              :deleteWorkflows="deleteWorkflows"
              :cloneWorkflow="cloneWorkflow"
              :resetWorkflow="resetWorkflow"
              :downloadCsvFile="downloadCsvFile"
              :sortField="$route.query.sortField"
              :sortOrder="$route.query.sortOrder"
              @openGoogleSheetsModal="openModal"
              @success="emitSuccess"
              @error="
                e => {
                  $emit('error', e)
                }
              "
              @sort="onSort"
              @updateWorkflowIdToDelete="updateWorkflowIdToDelete"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!showEmptyData() && !loading && count > 1"
        class="flex items-center justify-center"
      >
        <Paginator
          v-if="showPagination"
          @page="onPaginate"
          ref="page"
          v-model:first="first"
          :rows="20"
          :totalRecords="total"
          template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown "
        ></Paginator>
      </div>
    </div>
  </main>
</template>

<script>
import { downloadCsv, downloadWorkflowCsv } from '@/apis/automation-store/csv'
import { fetchAllExecutions } from '@/apis/executions'
import { getWorkflowAllNodes } from '@/apis/workflows'
import { WORKFLOW_CREATED_FROM, WORKFLOW_STATUS } from '@/common/constants'
import Select from '@/components/Select.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import resetWorkflowModal from '@/components/dataStore/resetWorkflowModal.vue'
import Paginator from 'primevue/paginator'
import { vue3Debounce } from 'vue-debounce'
import * as WorkflowAPI from '../../apis/workflows'
import SvgIcon from '../../components/SvgIcon.vue'
import Table from '../../components/dataStore/table.vue'
import Button from '@/components/Button.vue'
import ForceDeleteWorkfowModal from '@/components/dataStore/ForceDeleteWorkfowModal.vue'
import CloneWorkflowModal from '@/components/dataStore/CloneWorkflowModal.vue'
import { getAllPlatforms } from '@/apis/automation-store/Page1'
import { getAllConnectedAccounts } from '@/apis/connectedAccounts'
import imgUrl from '/src/assets/images/default-avatar-dark.svg'
import DataStoreSkeletonLoader from '@/components/dataStore/DataStoreSkeletonLoader.vue'

export default {
  name: 'DataStoreMain',
  directives: {
    debounce: vue3Debounce({})
  },
  components: {
    AutomationHeader,
    SvgIcon,
    Table,
    ToastContainer,
    Paginator,
    Select,
    resetWorkflowModal,
    Button,
    ForceDeleteWorkfowModal,
    DataStoreSkeletonLoader,
    CloneWorkflowModal
  },
  data() {
    return {
      selected: Number(this.$route.query.selected) || 0,
      workflows: [],
      workflowResults: [],
      modalData: null,
      resetWorkflowModal: false,
      loading: true,
      emptyData: null,
      count: 1,
      total: 0,
      first: 0,
      googleSheetsModal: false,
      showPagination: false,
      showCloneWorkflowModal: false,
      cloneWorkflowId: null,
      createdFromOptions: [
        { label: 'All', value: undefined },
        {
          label: 'Automation Store',
          value: WORKFLOW_CREATED_FROM.STORE
        },
        { label: 'Workflow builder', value: WORKFLOW_CREATED_FROM.BUILDER },
        {
          label: 'API',
          value: WORKFLOW_CREATED_FROM.API
        }
      ],
      statusOptions: [
        { label: 'All', value: undefined },
        { label: 'Completed', value: 'completed' },
        {
          label: 'Running',
          value: 'running'
        },
        {
          label: 'Paused',
          value: 'paused'
        },
        {
          label: 'Failed',
          value: 'failed'
        }
      ],
      platformOptions: [],
      platformIcon: null,
      accountOptions: [],
      accountIcon: null,
      showFilter: false,
      workflowIdToDelete: [],
      deleteWorkflowModal: false,
      pollTimeout: null,
      rerender: 0
    }
  },
  emits: ['error', 'success', 'warning'],
  controller: new AbortController(),

  async created() {
    this.getMode()
    await this.fetchData()
    if (this.$route.query.page) {
      this.first = (this.$route.query.page - 1) * 20
    }
    this.startPollingData()
  },

  beforeUnmount() {
    clearTimeout(this.pollTimeout)
  },

  computed: {
    getColumns() {
      if (this.selected === 0) {
        return [
          {
            name: 'Workflow Name',
            sortable: true,
            key: 'name'
          },
          {
            name: 'Created On',
            sortable: true,
            key: 'createdAt'
          },
          {
            name: 'Elapsed Time'
          },
          {
            name: 'Total Run Time'
          },
          {
            name: 'Status',
            sortable: true,
            key: 'isScheduled'
          },
          {
            name: 'Platform'
          },
          {
            name: 'Scheduled'
          },
          {
            name: 'Actions',
            isInvisible: true,
            buttonWidth: true
          },
          {
            name: 'More Options',
            isInvisible: true
          }
        ]
      } else {
        return [
          {
            name: 'Workflow Name',
            sortable: true,
            key: 'name'
          },
          {
            name: 'Created On',
            sortable: true,
            key: 'createdAt'
          },
          {
            name: 'Trigger'
          },
          {
            name: 'Status'
          },
          {
            name: 'Total Runtime'
          },
          {
            name: 'Platform'
          },
          {
            name: 'Actions',
            isInvisible: true
          },
          {
            name: 'More Options',
            isInvisible: true
          }
        ]
      }
    }
  },

  watch: {
    async selected(newTab, oldTab) {
      if (oldTab !== newTab) {
        await this.updateQuery({
          page: 1,
          selected: newTab,
          sortField: undefined,
          sortOrder: undefined,
          createdFrom: undefined,
          status: undefined
        })

        this.first = 0
        this.showPagination = false
        this.showFilter = false

        this.fetchData()
      }
    }
  },
  methods: {
    async onPaginate(e) {
      await this.updateQuery({
        page: Number(e.page + 1)
      })
      this.fetchData()
    },
    async updateQuery(updates) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          ...updates
        }
      })
    },

    onError(data) {
      this.$refs.toast.addToast({
        timeout: 7000,
        text: 'Error Occurred!',
        color: 'red',
        caption: data,
        action: false,
        close: true,
        modalData: null
      })
    },

    showEmptyData() {
      if (this.selected === 0) {
        return this.workflows
          ? Array.isArray(this.workflows)
            ? this.workflows.length === 0
            : false
          : true
      } else if (this.selected === 1) {
        return this.workflowResults
          ? Array.isArray(this.workflowResults)
            ? this.workflowResults.length === 0
            : false
          : true
      }
    },

    getMode() {
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? (this.emptyData = 'empty-data-dark')
        : (this.emptyData = 'empty-data-light')
    },

    cancelOldRequest() {
      this.$options.controller.abort('request cancelled')
      this.$options.controller = new AbortController()
    },

    async fetchData() {
      let start = 0
      if (this.$route.query.page) {
        start = (this.$route.query.page - 1) * 20
      }

      this.loading = true

      await this.getFilterOptions()

      const fetchFunction =
        this.selected === 0 ? this.fetchWorkflows : this.fetchExecutions

      this.cancelOldRequest()
      const response = await fetchFunction(start, 20)
      if (!response) return
      this.count = Math.ceil(response.total / 20)
      this.total = response.total

      if (this.selected === 0) {
        this.workflows = response.data
      } else if (this.selected === 1) {
        this.workflowResults = response.data
      }

      this.loading = false
      this.showPagination = true
    },

    async startPollingData() {
      try {
        let newArr = []
        this.workflows.forEach(element => {
          if (element.isRunning) {
            newArr.push(element._id)
          }
        })

        if (newArr.length > 0) {
          let idString = newArr.toString()
          const response = await WorkflowAPI.getWorkflowStatus(idString)

          const responseMap = new Map()
          for (const item of response.data) {
            responseMap.set(item._id, item)
          }

          let latestWorkflow = this.workflows.map(item => {
            if (responseMap.has(item._id)) {
              if (responseMap.get(item._id).isRunning === false) {
                return responseMap.get(item._id)
              } else {
                return item
              }
            } else {
              return item
            }
          })

          this.workflows = latestWorkflow

          this.pollTimeout = setTimeout(() => {
            this.startPollingData()
          }, 5000)
        } else {
          return
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getFilterOptions() {
      let platforms
      let accounts
      //check if platforms or accounts already available in session storage
      if (!sessionStorage.allPlatforms || !sessionStorage.accountsForFilter) {
        const [platformResponse, accResponse] = await Promise.all([
          getAllPlatforms(0, 50, ''),
          getAllConnectedAccounts(0, 50, true)
        ])

        //add them in session storage
        sessionStorage.setItem(
          'allPlatforms',
          JSON.stringify(platformResponse.data)
        )
        sessionStorage.setItem(
          'accountsForFilter',
          JSON.stringify(accResponse.data)
        )
        platforms = platformResponse.data
        accounts = accResponse.data
      } else {
        platforms = JSON.parse(sessionStorage.allPlatforms)
        accounts = JSON.parse(sessionStorage.accountsForFilter)
      }

      this.platformOptions = platforms
        .filter(item => item.type === 'automation' || item.name === 'openai')
        .map(item => {
          return { label: item.label, value: item._id, img: item.logoUrl }
        })

      //if url already has a filter update the icon for the option in select
      this.platformIconUpdate(this.$route.query.platformId)
      this.platformOptions.unshift({
        label: 'All',
        value: undefined,
        img: undefined
      })

      this.accountOptions = accounts.map(item => {
        return {
          label: item.name,
          value: item._id,
          img: [
            {
              src:
                item.platform.picture &&
                item.platform.picture.startsWith('https')
                  ? item.platform.picture
                  : imgUrl
            },
            {
              src: item.platform.logoUrl
            }
          ]
        }
      })
      this.accountIconUpdate(this.$route.query.connectedAccountId)
      this.accountOptions.unshift({
        label: 'All',
        value: undefined,
        img: undefined
      })
    },

    // TODO: USE VUEX to handle workflows storing
    async fetchWorkflows(start, count) {
      return WorkflowAPI.fetchAll(
        start,
        count,
        this.$route.query.q,
        this.$route.query.sortField,
        this.$route.query.status,
        this.$route.query.connectedAccountId,
        this.$route.query.platformId,
        this.$route.query.sortOrder,
        this.$route.query.createdFrom,
        this.$options.controller.signal
      )
    },
    async fetchOptions() {
      return this.fetchData()
    },
    async deleteWorkflows(workflowId) {
      const response = await WorkflowAPI.deleteWorkflow(workflowId)
      if (response.success) {
        this.workflows = this.workflows.filter(
          workflow => workflow._id !== workflowId
        )

        this.emitSuccess(`Workflow ${response.data.name} deleted successfully`)
      } else {
        this.$emit('error', response.message)
      }
    },
    async deleteResults(workflowResultId) {
      const response = await WorkflowAPI.deleteExecution({
        ids: [{ id: workflowResultId }]
      })
      if (response.success) {
        this.workflowResults = this.workflowResults.filter(
          result => result.executionDetails._id !== workflowResultId
        )

        this.emitSuccess(response.message)
      } else {
        this.$emit('error', response.message)
      }
    },
    async cloneWorkflow(workflowId) {
      this.cloneWorkflowId = workflowId
      this.showCloneWorkflowModal = true
    },
    successCloneWorkflow(data) {
      this.cloneWorkflowId = null
      this.emitSuccess(data.message)
      this.workflows.unshift({ ...data.data })
      this.rerender++
    },
    async resetWorkflow(workflowId, iconUrl, name) {
      this.modalData = { workflowId, iconUrl, name }
      this.resetWorkflowModal = true
    },
    async downloadCsvFile(data) {
      try {
        const nodeResponse = await getWorkflowAllNodes(data.workflowId)
        let nodeId
        let csvResponse
        let downloadLink
        if (data.inputSource === 'input') {
          nodeId = nodeResponse.data[0]._id
          csvResponse = await downloadCsv(data.executionId, nodeId)
          downloadLink = csvResponse.data.csvUrl
        } else if (data.inputSource === 'direct-input') {
          csvResponse = await downloadWorkflowCsv(data.executionId)
          downloadLink = csvResponse.data.zipUrl
        } else {
          nodeId = nodeResponse.data[1]._id
          csvResponse = await downloadCsv(data.executionId, nodeId)
          downloadLink = csvResponse.data.csvUrl
        }

        const filename = 'my-file.csv'

        const anchor = document.createElement('a')
        anchor.href = downloadLink
        anchor.download = filename
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
      } catch (error) {
        this.$emit('error', error)
      }
    },
    async fetchExecutions(start, count) {
      return fetchAllExecutions(
        start,
        count,
        this.$route.query.q,
        this.$route.query.sortField,
        this.$route.query.sortOrder,
        this.$route.query.createdFrom,
        this.$route.query.status,
        this.$options.controller.signal
      )
    },
    openModal(data) {
      this.googleSheetsModal = true
      this.modalData = data
    },
    emitSuccess(data) {
      this.$emit('success', data)
    },
    async onSort({ field, order }) {
      await this.updateQuery({
        sortField: field,
        sortOrder: order
      })
      this.fetchData()
    },

    async onFilterStatus(value) {
      await this.updateQuery({
        page: 1,
        status: value
      })

      this.first = 0
      this.showPagination = false

      this.fetchData()
    },
    async onFilterCreated(value) {
      await this.updateQuery({
        page: 1,
        createdFrom: value
      })

      this.first = 0
      this.showPagination = false

      this.fetchData()
    },
    async onFilterAccount(value) {
      this.accountIconUpdate(value)
      await this.updateQuery({
        page: 1,
        connectedAccountId: value
      })
      this.first = 0
      this.showPagination = false

      this.fetchData()
    },
    async onFilterPlatform(value) {
      this.platformIconUpdate(value)
      await this.updateQuery({
        page: 1,
        platformId: value
      })
      this.first = 0
      this.showPagination = false
      this.fetchData()
    },

    platformIconUpdate(value) {
      if (!value) {
        this.platformIcon = null
        return
      }
      const id = this.platformOptions.find(item => item.value === value)
      this.platformIcon = id.img
    },
    accountIconUpdate(value) {
      if (!value) {
        this.accountIcon = null
        return
      }
      const id = this.accountOptions.find(item => item.value === value)
      this.accountIcon = id.img
    },

    updateWorkflowIdToDelete(data) {
      this.workflowIdToDelete = data
    },
    bulkDeleteWorkflowSuccess(data) {
      this.$emit('success', data)
      this.workflowIdToDelete = []
      this.fetchData()
    }
  }
}
</script>

<style scoped>
:deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  background-color: theme('colors.blue.500');
  color: white;
}
:deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight):hover {
  background-color: theme('colors.blue.800');
  color: white;
}
</style>
