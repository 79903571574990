<template>
  <!-- TOP HEADER OF EVERY AUTOMATION PAGE -->
  <AutomationHeader title="Automation Store" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <component
      :is="currentComp"
      @platformSelected="platformSelected"
      :platformPassed="platformPassed"
    ></component>
  </main>
</template>

<script>
import Categories from '@/components/automationStore/Categories.vue'
import AutomationCards from '../../components/automationStore/AutomationCards.vue'

import clock from '@/assets/images/automationStore/clock.svg'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import AutomationSelector from '@/components/automationStore/AutomationSelector.vue'

export default {
  components: {
    AutomationCards,
    Categories,
    AutomationHeader,
    'automation-selector': AutomationSelector
  },
  name: 'AutomationStorePage1',
  data() {
    return {
      clock,
      currentComp: 'automation-selector',
      platformPassed: null
    }
  },
  beforeMount() {
    if (this.$route.query.platform) {
      this.currentComp = 'automation-selector'
    }
  },
  methods: {
    error(data) {
      this.$emit('error', data)
    },
    platformSelected(data) {
      this.currentComp = 'automation-selector'
      this.$router.push({
        query: {
          platform: data.name
        }
      })
    }
  }
}
</script>

<style></style>
