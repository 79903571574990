<template>
  <div class="mt-12 w-full">
    <div class="flex justify-between">
      <div class="border-l-2 border-gray-200 pl-6 dark:border-gray-600">
        <p class="text-sm font-semibold text-gray-600 dark:text-gray-400">
          Subscription Status
        </p>
        <div class="mt-2 flex items-center space-x-2">
          <div v-if="userData.planPaymentId !== 'free'" class="flex space-x-2">
            <button
              type="button"
              :class="[
                {
                  'text-green-500': !userData.isPaused,

                  'text-red-600': userData.isPaused
                }
              ]"
            >
              <svg class="h-4 w-4" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
              </svg>
            </button>
          </div>
          <p
            class="font-semibold text-green-500"
            v-if="planPaymentId.includes('v1')"
          >
            Subscription Active
          </p>
          <Button
            size="small"
            text="Manage Subscription"
            @click="manageSubscription()"
          />
        </div>
      </div>

      <div class="flex items-end justify-end">
        <Button
          size="small"
          text="Purchase Addons"
          @click="$emit('showAddonsModal')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createSelfServePortal } from '@/apis/settings/settings-billing'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import BillingHeader from '@/components/settings/billingHeader.vue'
import { mapActions, mapState } from 'vuex'
import { constants } from '@/common/constants'

export default {
  name: 'paymentMethodsSection',
  components: {
    BillingHeader,
    SvgIcon,
    Input,
    Button,
    Spinner
  },
  props: {
    subscriptionStatus: { type: String, default: 'Active' },
    renewalDate: { type: String, default: '17 April 2022' },
    currentPaymentMethod: { type: String, default: 'Visa ending ****1234' },
    planPaymentId: {}
  },
  data() {
    return {
      cardDetails: null
    }
  },
  computed: {
    ...mapState('user', ['userData'])
  },

  methods: {
    ...mapActions('user', ['saveUserData']),
    async manageSubscription() {
      try {
        Chargebee.init({
          site: constants.chargebeeSite
        })
        const cbInstance = Chargebee.getInstance()
        cbInstance.setPortalSession(async () => {
          const response = await createSelfServePortal()

          if (response['success']) {
            return response.data.portalSession
          } else if (response.message === 'Customer not found') {
            if (this.userData.planPaymentId.includes('v1')) {
              this.$emit('planEmit', {
                type: 'error',
                msg: 'Purchase Addons to Manage Subscription'
              })
              this.$emit('showAddonsModal')
              return
            } else {
              this.$emit('planEmit', {
                type: 'error',
                msg: 'Purchase a Plan to Manage Subscription'
              })
            }
          }
        })

        const cbPortal = cbInstance.createChargebeePortal()
        cbPortal.open({
          close() {
            // reload page
            window.location.reload()
          }
        })
      } catch (error) {
        console.log('error', error)
      }
    }
  }
}
</script>

<style scoped></style>
