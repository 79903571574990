<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Purchase Addons
          </p>
          <div>
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>
      <div class="px-4 py-3 sm:px-6 flex flex-col gap-2">
        <div v-if="loading" class="flex items-center justify-center">
          <Spinner />
        </div>
        <div v-else class="flex flex-col gap-2">
          <div
            class="flex items-center justify-between"
            v-for="data of addonsData"
          >
            <p class="font-semibold">{{ data.label }}</p>
            <Button
              size="small"
              text="Buy Now"
              @click="onBuyClick(data._id, data.planPaymentId)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import { getAddons } from '@/apis/settings/settings-billing'
import { mapState } from 'vuex'
export default {
  name: 'purchaseAddonModal',
  components: {
    Button,
    Input,
    Spinner
  },
  computed: {
    ...mapState('user', ['userData'])
  },
  data() {
    return {
      loading: true,
      addonsData: null
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getData() {
      try {
        const response = await getAddons()
        this.addonsData = response.data.filter(item => {
          if (
            !this.userData.planPaymentId.includes('free') &&
            !this.userData.planPaymentId.includes('v1') &&
            item.planPaymentId === '1-gb-of-proxy-bandwidth'
          ) {
            return false
          } else {
            return true
          }
        })
      } catch (error) {
        this.$emit('error', error)
      }
      this.loading = false
    },
    async onBuyClick(planId, paymentPlanId) {
      this.$emit('showAddonCheckoutModal', { planId, paymentPlanId })
    }
  }
}
</script>
