<template>
  <div class="pt-2 px-2">
    <Banner
      v-if="usingIsAdmin"
      :text="getAdminText()"
      bannerColor="red"
      :showClose="false"
    />
  </div>

  <remainingLimitsModal
    v-if="showLimitsTable"
    @close="showLimitsTable = false"
  />

  <header class="shrink-0">
    <div class="px-4 pb-5 sm:pt-5">
      <transition name="openedBar">
        <div v-if="showBanner" class="flex items-center justify-center pb-2">
          <Banner
            class="z-30"
            v-if="
              userData?.isEmailVerified === false ||
              userData?.isPhoneVerified === false
            "
            :text="verifyPlanText()"
            buttonText="Click Here To Verify"
            buttonUrl="/settings-verify"
            @close="showBanner = false"
          />

          <Banner
            class="z-30"
            v-else-if="showBanner && userData?.planPaymentId === 'free'"
            :text="freePlanText"
            buttonText="Manage Subscription."
            buttonUrl="/settings-billing/plan"
            @close="showBanner = false"
          />
          <Banner
            class="z-30"
            v-else-if="showBanner && userData?.planPaymentId === 'trial'"
            :text="getEndSubDate(userData?.subscriptionEndDate)"
            :bannerColor="trialBannerColor(userData?.subscriptionEndDate)"
            buttonText="Manage Subscription."
            buttonUrl="/settings-billing/plan"
            @close="showBanner = false"
          />
        </div>
      </transition>
      <div class="flex items-center justify-between">
        <div class="z-20 flex items-center gap-x-3">
          <Popper hover>
            <div
              class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
            >
              <div class="px-4 py-2.5">
                <div class="flex items-center">
                  <div class="mr-3 shrink-0">
                    <span class="sr-only"></span>

                    <SvgIcon
                      class="h-5 w-5 text-gray-900 dark:text-gray-50"
                      name="clock"
                    />
                  </div>

                  <span
                    class="text-sm font-medium text-gray-900 dark:text-gray-50"
                  >
                    <div v-if="executionTimeLeft">
                      <span>{{ executionTimeLeft }}</span>
                    </div>
                    <div v-else>
                      <Spinner />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                Cloud Run time Left
              </div>
            </template>
          </Popper>

          <Popper hover>
            <div
              class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
            >
              <div class="px-4 py-2.5">
                <div class="flex items-center">
                  <div class="mr-3 shrink-0">
                    <span class="sr-only"></span>
                    <SvgIcon
                      class="h-5 w-5 text-gray-900 dark:text-gray-50"
                      name="ai"
                    />
                  </div>
                  <span
                    class="text-sm font-medium text-gray-900 dark:text-gray-50"
                  >
                    <div
                      v-if="emailEnrichmentsLeft || emailEnrichmentsLeft === 0"
                    >
                      <span>{{ emailEnrichmentsLeft }}</span>
                    </div>
                    <div v-else>
                      <Spinner />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                Data Enrichment Credits
              </div>
            </template>
          </Popper>

          <Popper v-if="additionalData.desktopUnlimitedExecutionTime" hover>
            <div
              class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
            >
              <div class="px-4 py-2.5">
                <div class="flex items-center">
                  <div class="mr-3 shrink-0">
                    <span class="sr-only"></span>
                    <SvgIcon class="h-5 w-5 text-gray-600" name="desktop" />
                  </div>
                  <span
                    class="text-sm font-medium text-gray-900 dark:text-gray-50"
                  >
                    <div>
                      <span>Desktop Unlimited</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <template #content>
              <div class="w-max max-w-xl rounded bg-blue-50 p-2 text-sm">
                Running automation from desktop will not consume any cloud run
                time
              </div>
            </template>
          </Popper>

          <Button
            @click="showLimitsTable = true"
            text="View Your Limits"
            left-icon="tooltip"
            color="secondary"
          />

          <Popper v-if="userRole && userRole !== 'owner'" hover>
            <div
              class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
            >
              <div class="px-4 py-2.5">
                <div class="flex items-center">
                  <div class="mr-3 shrink-0">
                    <span class="sr-only"></span>

                    <svg
                      v-if="userRole === 'viewer'"
                      class="mt-1 h-5 w-5 text-gray-900 dark:text-gray-50"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke="currentColor"
                    >
                      <path
                        d="M1.42012 8.71318C1.28394 8.49754 1.21584 8.38972 1.17772 8.22342C1.14909 8.0985 1.14909 7.9015 1.17772 7.77658C1.21584 7.61028 1.28394 7.50246 1.42012 7.28682C2.54553 5.50484 5.8954 1 11.0004 1C16.1054 1 19.4553 5.50484 20.5807 7.28682C20.7169 7.50246 20.785 7.61028 20.8231 7.77658C20.8517 7.9015 20.8517 8.0985 20.8231 8.22342C20.785 8.38972 20.7169 8.49754 20.5807 8.71318C19.4553 10.4952 16.1054 15 11.0004 15C5.8954 15 2.54553 10.4952 1.42012 8.71318Z"
                      />
                      <path
                        d="M11.0004 11C12.6573 11 14.0004 9.65685 14.0004 8C14.0004 6.34315 12.6573 5 11.0004 5C9.34355 5 8.0004 6.34315 8.0004 8C8.0004 9.65685 9.34355 11 11.0004 11Z"
                      />
                    </svg>
                    <svg
                      v-else-if="userRole === 'editor'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-900 dark:text-gray-50 icon icon-tabler icons-tabler-outline icon-tabler-user-edit"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" />
                      <path
                        d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"
                      />
                    </svg>
                    <svg
                      v-else-if="userRole === 'admin'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-900 dark:text-gray-50"
                      viewBox="0 -960 960 960"
                      fill="currentcolor"
                    >
                      <path
                        d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 440q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-400Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Z"
                      />
                    </svg>
                  </div>
                  <span
                    class="text-sm font-medium text-gray-900 dark:text-gray-50"
                  >
                    <div v-if="userRole">
                      <span class="capitalize">{{ userRole }}</span>
                    </div>
                    <div v-else>
                      <Spinner />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                You are viewing someone else workspace with a role of
                <span class="capitalize text-blue-600 font-[500]">{{
                  userRole
                }}</span>
              </div>
            </template>
          </Popper>

          <!-- TODO: Might need later -->
          <!-- <Popper hover>
            <div
              class="rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
            >
              <div class="px-4 py-2.5">
                <div class="flex items-center">
                  <div
                    v-tooltip.bottom="'Email Verifiers Left'"
                    class="mr-3 shrink-0"
                  >
                    <span class="sr-only"></span>
                    <SvgIcon
                      class="h-5 w-5 text-gray-900 dark:text-gray-50"
                      name="mail-verification"
                    />
                  </div>
                  <span
                    class="text-sm font-medium text-gray-900 dark:text-gray-50"
                  >
                    <div v-if="emailVerifiersLeft">
                      <span>{{ emailVerifiersLeft }}</span>
                    </div>
                    <div v-else>
                      <Spinner />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <template #content>
              <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                Email Verifiers Left
              </div>
            </template>
          </Popper> -->
        </div>

        <div class="z-10 flex items-center justify-end space-x-4">
          <Backdrop
            v-if="showNotifications"
            @click="showNotifications = !showNotifications"
          />
          <Backdrop
            v-if="showSwitchWorkspace"
            @click="showSwitchWorkspace = !showSwitchWorkspace"
          />
          <div class="relative">
            <Button
              @click="showNotifications = !showNotifications"
              class="rounded-lg !border border-gray-200 bg-white p-2.5 text-gray-500 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-gray-400 dark:focus:ring-offset-gray-900"
              color="iconOnly"
              icon="bell"
            />
            <span
              v-if="notificationNumber > 0 && notificationNumber !== null"
              class="absolute right-0 top-0 flex h-5 w-5 -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full bg-red-600 text-xs text-white ring-2 ring-white"
              >{{ notificationNumber < 9 ? notificationNumber : `9+` }}</span
            >
            <NotificationDropdown
              v-if="showNotifications"
              @closeNotificaiton="showNotifications = false"
            />
          </div>

          <div v-if="usingIsAdmin">
            <Button text="Logout" color="terriary" @click="logout" />
          </div>
          <div v-else class="relative">
            <Button
              class="!p-2"
              @click="showSwitchWorkspace = !showSwitchWorkspace"
              color="tertiary"
              :text="workspaceName"
              rightIcon="down-arrow"
              ><span class="sr-only"></span>

              <!-- TODO: WILL NEED LATER -->
              <!-- <img
                class="h-6 w-6 shrink-0 rounded-full bg-gray-300"
                src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=256&amp;h=256&amp;q=80"
                alt="" /> -->

              <span
                class="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-blue-300 bg-blue-100 dark:border-blue-600 dark:bg-blue-500"
              >
                <Popper hover>
                  <span
                    class="text-xs font-medium uppercase leading-none text-blue-600 dark:text-blue-50"
                  >
                    {{ nameInitials }}
                  </span>
                  <template #content>
                    <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                      {{ displayName }}
                    </div>
                  </template></Popper
                >
              </span>
              <span class="ml-3"></span
            ></Button>
            <SwitchWorkspaceDropdown
              :isLoggingOut="loggingOut"
              @changeWorkspaceEmit="workspaceChange"
              @onLogout="logout"
              v-if="showSwitchWorkspace"
            />
          </div>

          <!-- <Button
            @click="changeMode"
            color="iconOnly"
            icon="moon"
            class="rounded-lg !border border-gray-200 bg-white p-2.5 text-gray-500 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-gray-400 dark:focus:ring-offset-gray-900"
          /> -->
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import UserProfile from '@/common/userWrapper'
import Backdrop from '@/components/Backdrop.vue'
import Button from '@/components/Button.vue'
import NotificationDropdown from '@/components/NotificationDropdown.vue'
import Banner from '@/components/Banner.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SwitchWorkspaceDropdown from '@/components/SwitchWorkspaceDropdown.vue'
import Popper from 'vue3-popper'
import { mapState, mapGetters } from 'vuex'
import { formatDate, formatTime } from '@/common/functions/formatDateAndTime'
import remainingLimitsModal from '@/components/settings/remainingLimitsModal.vue'

export default {
  name: 'Header',
  components: {
    SvgIcon,
    Button,
    NotificationDropdown,
    Backdrop,
    SwitchWorkspaceDropdown,
    Spinner,
    Popper,
    Banner,
    remainingLimitsModal
  },
  data() {
    return {
      showNotifications: false,
      showSwitchWorkspace: false,
      loggingOut: false,
      back: '',
      showBanner: true,
      showLimitsTable: false,
      role: 'owner',
      freePlanText: `<p class="flex-1 text-sm font-normal text-gray-900">You are on free plan, this will expire soon.</p>`
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('user', ['executionTimeLeft']),
    ...mapState('user', ['emailVerifiersLeft']),
    ...mapState('user', ['emailEnrichmentsLeft']),
    ...mapState('user', ['notificationNumber']),
    ...mapState('user', ['userWorkspaceData']),
    ...mapState('user', ['additionalData']),
    ...mapState('settings', ['userRole']),

    usingIsAdmin() {
      return sessionStorage.getItem('usingAdminMode')
    },

    nameInitials() {
      if (this.userData?.firstname && this.userData?.lastname) {
        return `${this.userData?.firstname?.charAt(
          0
        )}${this.userData?.lastname?.charAt(0)}`
      } else if (this.userData?.firstname || this.userData?.lastname) {
        return (
          `${this.userData?.firstname?.charAt(0)}` ||
          `  ${this.userData?.lastname?.charAt(0)}`
        )
      } else {
        //Texau User
        return `TU`
      }
    },
    displayName() {
      if (this.userData?.firstname && this.userData?.lastname) {
        return `${this.userData?.firstname} ${this.userData?.lastname}`
      } else if (this.userData?.firstname || this.userData?.lastname) {
        return `${this.userData?.firstname}` || `  ${this.userData?.lastname}`
      } else {
        return `Texau User`
      }
    },
    workspaceName() {
      // Check if using admin mode, return null if true
      if (sessionStorage.getItem('usingAdminMode')) {
        return null
      }

      // Retrieve workspaceId from cookies
      const workspaceId = window.$cookies.get('workspaceId')

      // Find the workspace based on the presence of workspaceId
      const obj = workspaceId
        ? this.userWorkspaceData.find(item => item.workspaceId === workspaceId)
        : this.userWorkspaceData.find(o => o.isDefault === true)

      // Return the workspace name if the object is found
      return obj ? obj.name : null
    },

    showBackButton() {
      return this.back != null
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.workflowId) {
        this.back = null
        return
      }
      this.back = this.$router.options.history.state.back
    }
  },

  methods: {
    getRole() {
      let obj = this.userWorkspaceData.find(
        item => item.workspaceId === window.$cookies.get('workspaceId')
      )

      for (const role of this.getMetaData['roles']) {
        if (role.id === obj.roleId) {
          this.role = role.name
          break
        }
      }
    },
    compareDates(dateString) {
      // Parse the input date string
      const inputDate = new Date(dateString)

      // Get the current date
      const currentDate = new Date()

      // Calculate the difference in time (in milliseconds)
      const differenceInTime = Math.abs(currentDate - inputDate)

      // Convert time difference from milliseconds to days
      const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24)

      // Return 1 if the difference is 3 days or less, otherwise return 0
      return differenceInDays <= 3 ? 1 : 0
    },

    trialBannerColor(date) {
      if (this.compareDates(date) === 1) {
        return 'red'
      } else {
        return 'reddit'
      }
    },

    getEndSubDate(date) {
      if (this.compareDates(date) === 1) {
        return `<p class="flex-1 text-sm font-normal text-gray-900"> You are on <span class=" font-semibold text-red-500" >Trial</span> plan till <span class=" font-semibold text-red-500" >${formatDate(
          date
        )}</span> ${formatTime(date)} . </p>`
      } else {
        return `<p class="flex-1 text-sm font-normal text-gray-900"> You are on <span class=" font-semibold text-orange-500" >Trial</span> plan till <span class=" font-semibold text-orange-500" >${formatDate(
          date
        )}</span> ${formatTime(date)} . </p>`
      }
    },

    async logout() {
      sessionStorage.clear()
      this.loggingOut = true
      //shut down intercom session
      window.Intercom('shutdown')
      await UserProfile.logout()
      this.loggingOut = false
      window.open(`${window.location.origin}/login`, '_self')
    },

    // changeMode() {
    //   if (
    //     localStorage.theme === 'dark' ||
    //     (!('theme' in localStorage) &&
    //       window.matchMedia('(prefers-color-scheme: dark)').matches)
    //   ) {
    //     localStorage.setItem('theme', 'light')
    //     document.documentElement.classList.remove('dark')
    //   } else {
    //     localStorage.setItem('theme', 'dark')
    //     document.documentElement.classList.add('dark')
    //   }
    // },

    workspaceChange(data) {
      this.$emit('emitSuccess', `Workspace Successfully Changed to ${data}`)
    },
    getAdminText() {
      return `⚠ You are accessing ${sessionStorage.getItem(
        'userWorkspaceName'
      )} workspace of user - ${sessionStorage.getItem(
        'userEmail'
      )} in Super Admin Mode. ⚠`
    },

    /**
     * Generates a verification message based on the user's email, phone, and plan status.
     *
     * - Prompts verification for both email and phone if neither is verified.
     * - Prompts for email verification, with or without a trial message, if only the email is unverified.
     * - Prompts for phone verification if only the phone is unverified.
     *
     * @returns {string} A short HTML verification message.
     */
    verifyPlanText() {
      const { isEmailVerified, isPhoneVerified, planAllocated } =
        this.userData || {}

      const emailNotVerified = isEmailVerified === false
      const phoneNotVerified = isPhoneVerified === false

      if (emailNotVerified && phoneNotVerified) {
        return `<p class="flex-1 text-sm font-normal text-gray-900">Please Verify Your Email And Phone Number To Start Your Trial And Run Automations.</p>`
      }

      if (emailNotVerified) {
        const trialMessage = planAllocated
          ? 'To Start Your Trial And Run Automations.'
          : 'To Run Automations.'
        return `<p class="flex-1 text-sm font-normal text-gray-900">Please Verify Your Email ${trialMessage}</p>`
      }

      return `<p class="flex-1 text-sm font-normal text-gray-900">Please Verify Your Phone Number To Start Your Trial And Run Automations.</p>`
    }
  }
}
</script>

<style>
.openedBar-enter-active {
  transition: all 0.3s ease;
  animation: modal 0.3s ease-out;
}
.openedBar-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>
