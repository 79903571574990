<!-- Input Component -->
<!-- This component has 3 types of input primary(default), error, disabled -->
<!-- To access error and disabled input pass v-bind:error OR disabled='true' -->
<!-- Additional props: text(input placeholder), type(default is text), captionText(text under input), leftIcon(left icon), rightIcon(right icon), action(button), svgSrc(source of icon) -->
<!-- To add icons pass the position of icon v-bind:leftIcon OR rightIcon="true" then svgSrc -->

<template>
  <div>
    <label v-if="isLabel" for="" :class="labelClass" class="flex">
      {{ label }} {{ isRequired === false ? '(Optional)' : null }}
      <SvgIcon
        class="ml-1 mb-3 h-2 w-2 text-red-600"
        name="star"
        v-if="isRequired"
      />
    </label>
    <div
      :class="[
        {
          relative: action || rightIcon || leftIcon || leftImageUrl,
          'relative mt-1.5': isLabel && labelClass !== 'sr-only'
        }
      ]"
    >
      <vee-field
        :type="type"
        :name="name"
        :id="name"
        :min="min"
        :max="max"
        :value="modelValue"
        @input="updateValue"
        @blur="$emit('blur')"
        class="block w-full rounded-lg border px-3 py-2.5 placeholder-gray-500 transition-all duration-200 dark:placeholder-gray-400 sm:text-sm"
        :class="[
          {
            'border-red-600  text-red-600 caret-red-600 hover:border-red-700 focus:border-red-600 focus:ring-red-600 dark:border-red-500 dark:bg-gray-900 dark:placeholder-gray-400 dark:focus:border-red-500 dark:focus:ring-red-500':
              displayError,
            ' border-green-600  text-green-600 caret-green-600 hover:border-green-700 focus:border-green-600 focus:ring-green-600 dark:border-green-500 dark:bg-gray-900 dark:placeholder-gray-400 dark:focus:border-green-500 dark:focus:ring-green-500':
              displayChange,
            'border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500':
              primary,
            'opacity-50': disabled,
            'pointer-events-none': readonly,
            'py-2.5 pr-3 pl-11': leftIcon || leftImageUrl,
            '!pr-10': rightIcon
          }
        ]"
        :placeholder="text"
        :readonly="disabled || readonly"
        :disabled="disabled"
      />

      <!-- ACTION BUTTON  -->
      <div
        v-if="action"
        class="absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <button
          type="button"
          class="p-1 text-base font-semibold text-blue-600 transition-all duration-200 hover:text-blue-700 focus:outline-none focus:ring-0 dark:text-blue-400 dark:hover:text-blue-500 sm:text-sm"
          :class="[
            {
              'pointer-events-none text-gray-500 opacity-50 hover:text-gray-700':
                disabled
            }
          ]"
          :disabled="disabled"
        >
          Action
        </button>
      </div>

      <!-- RIGHT ICON -->
      <div
        v-if="rightIcon"
        class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 dark:text-gray-50"
      >
        <!-- <svg
          class="h-5 w-5 text-gray-400"
          :class="[{ 'opacity-50': disabled }]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
          />
        </svg> -->
        <SvgIcon
          :class="`h-5 w-5 ` + rightIconMouse"
          :name="rightIcon"
          @click="rightIconClick"
        />
      </div>

      <!-- LEFT ICON -->
      <div
        v-if="leftIcon || leftImageUrl"
        class="absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <SvgIcon v-if="leftIcon" :name="leftIcon" />

        <img
          v-if="leftImageUrl"
          class="h-5 w-5 shrink-0 rounded-full object-cover"
          :src="leftImageUrl"
          alt=""
        />
        <!-- <img v-if="leftIcon" class="h-5 w-5" :src="svgSrc" alt="" /> -->
      </div>
    </div>
    <!-- CAPTION TEXT -->
    <p
      v-if="captionText"
      class="mt-2 text-sm"
      :class="[
        { 'text-red-600': displayError },
        { 'text-gray-500': primary },
        { 'text-gray-500 opacity-50': disabled }
      ]"
    >
      {{ captionText }}<slot></slot>
    </p>
    <!-- Form Validation Error Message -->
    <!-- <p class="mt-2 text-sm text-red-600"><ErrorMessage :name="name" /></p> -->
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
  name: 'Input',
  data: function () {
    return {
      primary: true
    }
  },
  emits: ['update:modelValue', 'blur', 'onRightIconClick'],
  props: {
    text: { default: 'write something...' },
    error: { type: Boolean, default: false },
    change: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    action: { type: Boolean, default: false },
    rightIcon: { type: String, default: '' },
    rightIconMouse: { type: String, default: 'cursor-default' },
    leftIcon: { type: String, default: '' },
    leftImageUrl: { type: String, default: null },
    type: { type: String, default: 'text' },
    captionText: String,
    modelValue: String,
    label: { type: String, default: '' },
    labelClass: { type: String, default: '' },
    svgSrc: String,
    name: { type: String, default: 'input' },
    readonly: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: undefined },
    min: { type: Number, required: false },
    max: { type: Number, required: false }
  },
  computed: {
    displayError() {
      if (this.error === true) {
        this.primary = false
        return true
      }
    },
    displayChange() {
      if (this.change === true) {
        this.primary = false
        return true
      }
    },
    isLabel() {
      return this.label !== ''
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value)
    },

    rightIconClick() {
      this.$emit('onRightIconClick')
    }
  },
  created() {},
  components: { SvgIcon }
}
</script>

<style></style>
