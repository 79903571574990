<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen items-stretch justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-md flex-col">
              <a href="#" title="TexAu" class="">
                <img
                  class="mx-auto h-12 w-auto xl:h-14"
                  src="@/assets/images/logo-alt.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <img
                    class="mx-auto h-40 w-auto"
                    src="@/assets/images/onboarding/illustrations/import.png"
                    alt=""
                  />

                  <div class="mt-12 text-center">
                    <Heading text="Import your accounts now" />
                    <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                      Login to the social media accounts you want to use in a
                      new tab, TexAu will now import them.
                    </p>

                    <div class="mt-8 flex items-center justify-between">
                      <Button
                        :show-loader="isLoading"
                        @click="onImportClick($event)"
                        id="texau-extension-v2"
                        text="Install Extension"
                      />

                      <Button
                        :show-loader="skipLoading"
                        right-icon="right-arrow"
                        color="secondary"
                        text="Skip Import"
                        @click="onSkipImportClick"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Heading from '@/components/onboarding/Heading.vue'
import Button from '@/components/Button.vue'
import { updateUserDetail } from '@/apis/user'

import UserProfile from '@/common/userWrapper'
export default {
  // step-4
  name: 'triggerExtension',
  components: {
    OnboardingLeftColumn,
    Heading,
    Button
  },

  data() {
    return {
      isLoading: false,
      skipLoading: false
    }
  },

  methods: {
    async updateNextStep() {
      const responseUser = await updateUserDetail({
        currentOnboardingStep: 'Importing'
      })
      UserProfile.updateOnboardingStateInCookies('Importing')
      this.isLoading = false
      if (responseUser['success']) {
        await this.$router.push({
          path: '/onboarding/importing',
          query: {
            newImport: false
          }
        })
      } else {
        this.$emit('error', 'Error Occurred!')
      }
    },

    async onImportClick(e) {
      // this.isLoading = true;
      if (e.currentTarget.innerText === 'Install Extension') {
        //TODO: open chrome extension store link in new tab
        var newTab = window.open(
          'https://chromewebstore.google.com/detail/texau-v2/hbhdepogdnphlloancjiifodpchlkeam',
          '_blank'
        )
        newTab.focus()
      } else {
        await this.updateNextStep()
      }
    },
    async onSkipImportClick() {
      this.skipLoading = true
      const responseUser = await updateUserDetail({
        isOnboarded: true,
        currentOnboardingStep: null
      })
      let user_ = UserProfile.getUser()
      user_['isOnboarded'] = true
      delete user_['currentOnboardingStep']
      UserProfile.setUser(user_)
      this.skipLoading = false
      if (responseUser['success']) {
        await this.$router.go()
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: 'Error in moving to next step.',
          action: false,
          close: true
        })
      }
    }
  }
}
</script>

<style></style>
